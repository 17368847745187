import { combineReducers } from 'redux';
// import SetupReducer from './SetupReducer';
// import MediaReducer from './MediaReducer';
import {AlbumReducer, AlbumArtistReducer, AlbumGenreReducer } from './albumReducer'
import ArtistReducer from './artistReducer'
import GenreReducer from './genreReducer';
import TrackReducer from './trackReducer';
import UsersReducer from './usersReducer';

const rootReducer = combineReducers({
  // setupInfo: SetupReducer
  // mediaInfo: MediaReducer,
  albumInfo: AlbumReducer,
  artistInfo: ArtistReducer,
  genreInfo: GenreReducer,
  albumArtist: AlbumArtistReducer,
  albumGenre: AlbumGenreReducer,
  allTracks: TrackReducer,
  allUsers: UsersReducer,
  // artist_album: artist_albumReducer,
})

export default rootReducer;
// export type RootState = ReturnType<typeof rootReducer>