import { Dispatch } from "redux";
import {
  USER_FAIL,
  USER_LOADING,
  USER_SUCCESS,
  UserDispatchTypes,
  DOC_FAIL,
  DOC_LOADING,
  DOC_SUCCESS,
  DOC_STATUS_PROCESSING,
  DOC_STATUS_SUCCESS,
  DOC_STATUS_FAIL,
  DocDispatchTypes,
  DocStatusDispatchTypes,
  FETCH_USER_INFO,
} from "./userActionTypes";
import axios from "axios";
// import axiosInstance from "../utilities/axios";

export const fetchUserInfo = () => (dispatch:any) => {

  const userInfo = {
    first_name: "adonis",
    last_name: "tividad",
    password: "adonis123",
    email: "avtividad@yahoo.com",
    phone_no: "0501234567",
    city: "Dubai",
    country: "United Arab Emirates",
    // country: "Philippines",
    national_id: "ID123456789",
    passport: "PH1234567890",
    type: "individual",
    address: "",
    occupation: "singer",
  }
 
  dispatch({
    type: FETCH_USER_INFO,
    payload: userInfo,
  });
}
 
export const fetchUsers =
  (doc_status: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
    try {
      dispatch({
        type: USER_LOADING,
      });
      const res = await axios.post(
        // "http://localhost:5000/api/v1/user/fetch-users",{
        // "https://api.takeone.studio/api/v1/user/fetch-users",
        "https://api.takeone.tividad.com/api/v1/user/fetch-users",
        {
          doc_status,
        }
      );

      // console.log("res.data.results>>>>>>>>>>>>", doc_status, res.data.results);
      dispatch({
        type: USER_SUCCESS,
        payload: res.data.results,
      });
    } catch (e) {
      dispatch({
        type: USER_FAIL,
      });
    }
  };

export const fetchDocFiles =
  () => async (dispatch: Dispatch<DocDispatchTypes>) => {
    try {
      dispatch({
        type: DOC_LOADING,
      });
      const res = await axios.post(
        // "http://localhost:5000/api/v1/user/fetch-docfiles"
        // "https://api.takeone.studio/api/v1/user/fetch-docfiles"
        "https://api.takeone.tividad.com/api/v1/user/fetch-docfiles"
      );

      // console.log("res>>>>>", res);
      dispatch({
        type: DOC_SUCCESS,
        payload: res.data.results,
      });
    } catch (e) {
      dispatch({
        type: DOC_FAIL,
      });
    }
  };

export const updateDocStatusAll =
  (email: any, doc_status: any) =>
  async (dispatch: Dispatch<DocStatusDispatchTypes>) => {
    try {
      dispatch({
        type: DOC_STATUS_PROCESSING,
      });
      const res = await axios.post(
        // "http://localhost:5000/api/v1/user/update-doc-status-all",
        // "https://api.takeone.studio/api/v1/user/update-doc-status-all",
        "https://api.takeone.tividad.com/api/v1/user/update-doc-status-all",
        {
          email,
          doc_status,
        }
      );

      // console.log("res>>>>>", res);
      dispatch({
        type: DOC_STATUS_SUCCESS,
        payload: res.data.results,
      });
    } catch (e) {
      dispatch({
        type: DOC_STATUS_FAIL,
      });
    }
  };

export const updateDocStatus =
  (id: any, email: any, status: any) =>
  async (dispatch: Dispatch<DocStatusDispatchTypes>) => {
    try {
      dispatch({
        type: DOC_STATUS_PROCESSING,
      });

      // const res =
       await axios
        // .post("http://localhost:5000/api/v1/user/update-doc-status", {
        // .post("https://api.takeone.studio/api/v1/user/update-doc-status", {
        .post("https://api.takeone.tividad.com/api/v1/user/update-doc-status", {
          id,
          email,
          status,
        })
        .then((res: any) => {
          // console.log("res>>>>>", res);
          dispatch({
            type: DOC_STATUS_SUCCESS,
            payload: res.data.results,
          });
        });

      // axiosInstance
      //   .post("user/update-doc-status", {
      //     id,
      //     email,
      //     status,
      //   })
      //   .then((res: any) => {
      //     console.log("res>>>>>", res);
      //     dispatch({
      //       type: DOC_STATUS_SUCCESS,
      //       payload: res.data.results,
      //     });
      //   });
    } catch (e) {
      dispatch({
        type: DOC_STATUS_FAIL,
      });
    }
  };
