import { Dispatch } from "redux";
import { GENRE_FAIL, GENRE_LOADING, GENRE_SUCCESS, GenreDispatchTypes } from "./genreActionTypes";
import axios from "axios";

export const fetchGenres = () => async (dispatch: Dispatch<GenreDispatchTypes>) => {
  try {
    dispatch({
      type: GENRE_LOADING
    })
    //--here
    const res = await axios.get('https://takeone.meekd.org/api/genres/');
    
    dispatch({
      type: GENRE_SUCCESS,
      payload: res.data.results
    })

  } catch (e) {
    dispatch({
      type: GENRE_FAIL
    })
  }
};
 