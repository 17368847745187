import { Dispatch } from "redux";
import {
  TRACK_FAIL,
  TRACK_LOADING,
  TRACK_SUCCESS,
  TrackDispatchTypes,
  // TrackResults,
} from "./trackActionTypes";
import axios from "axios";

const toTitleCase = (str: any) => {
  return str.replace(/\w\S*/g, (txt: any) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const fetchTracks_old =
  () => async (dispatch: Dispatch<TrackDispatchTypes>) => {
    try {
      dispatch({
        type: TRACK_LOADING,
      });
      // alert('fetchTracks')
      const res = await axios.post(
        // "https://api.takeone.studio/api/v1/media/fetch-files"
        "https://api.takeone.tividad.com/api/v1/media/fetch-files"
      );

      const tracks = res.data.media_folders?.map((folder: any, id: number) => {
        // const image = `https://api.takeone.studio/media/${folder}/${folder}.jpg`;
        // const src = `https://api.takeone.studio/media/${folder}/master.m3u8`;
        const image = `https://api.takeone.tividad.com/media/${folder}/${folder}.jpg`;
        const src = `https://api.takeone.tividad.com/media/${folder}/master.m3u8`;
        const arrTrack = (folder + "-")
          .replaceAll("---", "-")
          .replaceAll("--", "-")
          .replaceAll("_", " ")
          .split("-");

        // const artist = toTitleCase(arrTrack[0].trim());
        const artists = [
          { name: toTitleCase(arrTrack[0].trim()).replace(/[^0-9a-z ]/gi, "") },
        ];
        const title = toTitleCase(arrTrack[1].trim()).replace(
          /[^0-9a-z ]/gi,
          ""
        );
        const title_ar = "";
        return {
          id,
          name: title,
          description: "",
          name_ar: title_ar,
          description_ar: "",
          artists,
          image,
          status: 0,
          sources: [
            {
              src,
              type: "application/x-mpegURL",
              res: "1080",
              label: "1080",
            },
          ],
        };
      });

      // console.log("res.data.media_folders>>>>", res.data.media_folders);
      // console.log("tracks>>>>", tracks);

      dispatch({
        type: TRACK_SUCCESS,
        payload: tracks,
      });
    } catch (e) {
      dispatch({
        type: TRACK_FAIL,
      });
    }
  };

export const fetchTracks =
  () => async (dispatch: Dispatch<TrackDispatchTypes>) => {
    try {
      dispatch({
        type: TRACK_LOADING,
      });
      // alert('fetchTracks')
      const res = await axios.post(
        // "https://api.takeone.studio/api/v1/media/fetch-all-tracks"
        "https://api.takeone.tividad.com/api/v1/media/fetch-all-tracks"
        // "http://localhost:5000/api/v1/media/fetch-all-tracks"
      );

      const tracks = res.data?.map((data: any) => {
        // console.log("data>>>>", data);
        // const image = `https://api.takeone.studio/media/${data.image.replace(
        const image = `https://api.takeone.tividad.com/media/${data.image.replace(
          ".jpg",
          ""
        )}/${data.image}`;
        // const src = `https://api.takeone.studio/media/${data.video}`;
        const src = `https://api.takeone.tividad.com/media/${data.video}`;
 
        const artists = [
          { name: toTitleCase(data.artist.trim()).replace(/[^0-9a-z ]/gi, "") },
        ];

        const name = toTitleCase(data.title.trim()).replace(
          /[^0-9a-z ]/gi,
          ""
        );
        const { id, description, description_ar } = data;
        // const id = data.id;
        // const description = data.description;
        // const description_ar = data.description_ar;
        const name_ar = data.title_ar;

        // console.log("res>>>>", res.data.description);

        return {
          id,
          name,
          description,
          name_ar,
          description_ar,
          artists,
          image,
          status: 0,
          sources: [
            {
              src,
              type: "application/x-mpegURL",
              res: "1080",
              label: "1080",
            },
          ],
        };
      });

      // console.log("res.data.media_folders>>>>", res.data.media_folders);
      // console.log("tracks>>>>", tracks);

      dispatch({
        type: TRACK_SUCCESS,
        payload: tracks,
      });
    } catch (e) {
      dispatch({
        type: TRACK_FAIL,
      });
    }
  };
