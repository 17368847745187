import FileViewer from "react-file-viewer";
import { IonIcon, IonModal } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import "../styles/fileviewer.css";

// const file = "https://api.takeone.studio/user/adonis@tividad.com/avtividad-3hX3Ewgn9NB4HWBk9LACyU.pdf";
// const type = "pdf";
// const file =
//   "https://api.takeone.studio/user/adonis@tividad.com/ADONIS-TIVIDAD-Cover-letter.docx";
// const type = "docx";
// const file =
//   "https://api.takeone.studio/user/adonis@tividad.com/1618830894567.png";
// const type = "png";
// const file =
//   "https://api.takeone.studio/user/adonis@tividad.com/photo.jpg";
// const type = "jpg";
// const file =
//   "https://api.takeone.studio/user/adonis@tividad.com/coca_cola_1080p.mp4";
// const type = "mp4";
// const file =
//   "https://api.takeone.studio/user/adonis@tividad.com/dua_lipa_-_cool.mp4";
// const type = "mp4";

const ReactFileViewer = ({ isOpen, onClose, file = "" }) => {
  const onDismiss = () => {
    onClose(1);
  };

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  const getFileExtension = (filename ) => {
    const re = /(?:\.([^.]+))?$/;
    const test = re.exec(filename);
    // console.log('test>>>>>>>>>>',test)
    if (test) {
      return test[1];
    } else {
      return "";
    }
  };
  // const getFileExtension = (name) => {
  //   console.log("name.slice(-5)>>>", name.slice(-5));
  //   return name.slice(-5).split(".")[1];
  // };
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      cssClass="modal-message modal-viewer"
    >
      <IonIcon
        onClick={onClose}
        className="close cursor-pointer"
        icon={closeCircle}
        size="large"
      />
      <FileViewer
        fileType={getFileExtension(file)}
        filePath={file}
        allowFullScreen={true}
        onError={onError}
      />
    </IonModal>
  );
};

export default ReactFileViewer;
