import React, { useEffect, useState } from "react";
// import { Redirect, Route } from 'react-router-dom';
import { IonApp } from "@ionic/react";
// import { IonReactRouter } from '@ionic/react-router';
import { Provider } from "react-redux";
import { PlatformProfider } from "./contexts/PlatformContext";
import { UserProfider } from "./contexts/UserContext";
import { LocalPlaylistProfider } from "./contexts/LocalPlaylistContext";
import queryString from "query-string";

import store from "./store";
import "./react-toggle.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Theme variables */
import "./theme/dark.css";
import "./styles/main.css";

// import './theme/variables.css';
// import './App.css';
import "./takeone.css";

// import { albumsOutline, albumsSharp, download, downloadOutline, home, homeOutline, homeSharp, search, searchOutline } from 'ionicons/icons';
// import MenuTab from './components/MenuTab';
import Menu from "./components/Menu";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { isMobile, isAndroid } from "react-device-detect";

// import { AndroidFullScreen } from "@ionic-native/android-full-screen";
// import { NavigationBar } from "@ionic-native/navigation-bar";

// import Home from './components/Home';

const platform = Capacitor.getPlatform(); //-- web, ios, android
//---- const platform = 'android'; //-- temporary

const App: React.FC = () => {
  const [selectedIonTab, setSelectedIonTab] = useState("home");
  useEffect(() => {
    if (platform.toString() !== "web") {
      SplashScreen.hide();

      /************** remove comment to activate   - 2022-01-31
       //-- auto-hide navigation bar on Android devices
       if (platform.toString() === "android") {
         const autoHideNavigationBar = true;
         NavigationBar.setUp(autoHideNavigationBar);
         
         //------- https://github.com/mesmotronic/cordova-plugin-fullscreen
         AndroidFullScreen.isImmersiveModeSupported()
         .then(() => {
           //--- remove comment to enable andriod fullscreen mode
           AndroidFullScreen.immersiveMode();
          })
          .catch((err) => console.log("err>>>", err));
        }
        */
    } else {
      let params = queryString.parse(window.location.search);
      if (isMobile && isAndroid && !params.uveid) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=takeone.studio";
      }
      /****** redirects to playstore
       */
    }

    // setTimeout(() => {
    //   SplashScreen.hide();
    // }, 1000);
    setSelectedIonTab((window.location.pathname + "/").split("/")[1]);
  }, []);

  return (
    <>
      {!(platform.toString() === "web" && isMobile && isAndroid) && (
        <Provider store={store}>
          <IonApp>
            <PlatformProfider>
              <UserProfider>
                <LocalPlaylistProfider>
                  <div className={`top-cover ${platform.toString()}`}></div>
                  {<Menu />}
                  {/* {
                platform.toString() === 'web'
                  ?
                  // <Menu platform={platform} />
                  <Menu />
                  :
                  <MenuTab />
              } */}
                  {console.log("selectedIonTab >>", selectedIonTab)}
                </LocalPlaylistProfider>
              </UserProfider>
            </PlatformProfider>
          </IonApp>
        </Provider>
      )}
    </>
  );
};

export default App;

// function useMemo(arg0: () => void) {
//   throw new Error('Function not implemented.');
// }
