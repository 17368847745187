export const ALBUM_LOADING = "ALBUM_LOADING";
export const ALBUM_FAIL = "ALBUM_FAIL";
export const ALBUM_SUCCESS = "ALBUM_SUCCESS";
export const FETCH_ALBUMS = "FETCH_ALBUMS";
export const GENRE_ALBUM_LOADING = "GENRE_ALBUM_LOADING";
export const GENRE_ALBUM_FAIL = "GENRE_ALBUM_FAIL";
export const GENRE_ALBUM_SUCCESS = "GENRE_ALBUM_SUCCESS";
export const FETCH_GENRE_ALBUMS = "FETCH_GENRE_ALBUMS";

export type AlbumResults = {
  item: {
    id: number,
    title: string,
    album: object,
    artists: object
  }
}

export type AlbumType = {
  items: AlbumResults[],
}

export interface AlbumLoading {
  type: typeof ALBUM_LOADING
}

export interface AlbumFail {
  type: typeof ALBUM_FAIL
}

export interface AlbumSuccess {
  type: typeof ALBUM_SUCCESS,
  payload: AlbumType[]
}



export type GenreAlbumResults = {
  item: {
    id: number,
    title: string,
    album: object,
    artists: object
  }
}

export type GenreAlbumType = {
  items: GenreAlbumResults[],
}

export interface GenreAlbumLoading {
  type: typeof GENRE_ALBUM_LOADING
}

export interface GenreAlbumFail {
  type: typeof GENRE_ALBUM_FAIL
}

export interface GenreAlbumSuccess {
  type: typeof GENRE_ALBUM_SUCCESS,
  payload: GenreAlbumType[]
}

export type AlbumDispatchTypes = AlbumLoading | AlbumFail | AlbumSuccess
export type GenreAlbumDispatchTypes = GenreAlbumLoading | GenreAlbumFail | GenreAlbumSuccess