export const GENRE_LOADING = "GENRE_LOADING";
export const GENRE_FAIL = "GENRE_FAIL";
export const GENRE_SUCCESS = "GENRE_SUCCESS";
export const FETCH_GENRES = "FETCH_GENRES";

export interface GenreLoading {
  type: typeof GENRE_LOADING
}

export interface GenreFail {
  type: typeof GENRE_FAIL
}

export type GenreType = {
  items: GenreResults[],
}

export type GenreResults = {
  item: {
    id: number,
    title: string,
    album: object,
    artists: object
  }
}

export interface GenreSuccess {
  type: typeof GENRE_SUCCESS,
  payload: GenreType[]
}

export type GenreDispatchTypes = GenreLoading | GenreFail | GenreSuccess