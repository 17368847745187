import { IonIcon, IonInput, IonModal } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { ACCESS_TYPE } from "../definitions";
import axiosInstance from "../utilities/axios";

const UserOTP: React.FC<any> = ({ isOpen, onClose, email, setAccessType }) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const field1 = useRef<HTMLIonInputElement>(null);
  const field2 = useRef<HTMLIonInputElement>(null);
  const field3 = useRef<HTMLIonInputElement>(null);
  const field4 = useRef<HTMLIonInputElement>(null);
  const arrIndex = useRef<number>(0);
  const arrFields: any = [field1, field2, field3, field4];

  const onDismiss = () => {
    // setAccessType(ACCESS_TYPE.SIGN_IN);
    onClose(1);
  };

  const gotoNextField = (event: any) => {
    console.log("nextElement>>>", event.keyCode);
    setErrorMessage("");
    if (event.keyCode === 9) {
    } else if (event.keyCode === 8) {
      if (arrIndex.current > 0) {
        arrIndex.current--;
      }
    } else {
      if (arrIndex.current < arrFields.length - 1) {
        arrIndex.current++;
      } else {
        //-- sample wrong otp
        // alert("invalid otp");
        let otp = "";
        arrFields.map((field: any) => {
          otp += field.current.value;
          field.current.value = null;
        });
        arrIndex.current = 0;
        axiosInstance
          .post("/user/verify-otp", {
            email,
            isVerification: true,
            otp,
          })
          .then((res: any) => {
            //handle success
            // console.log(res);
            // alert(res.data.status + " | " + res.data.msg);
            // if ([200, 403].includes(res.data.status)) {
            if ([200, 403].includes(res.data.status)) {
              setAccessType(ACCESS_TYPE.SIGN_IN);
              onDismiss();
            } else {
              setErrorMessage(res.data.msg);
            }
          });
      }
    }
    arrFields[arrIndex.current].current.setFocus();
    // arrFields[arrIndex.current].current.select();
    // arrFields[arrIndex.current].current.setSelectionRange(0, arrFields[arrIndex.current].current.value.length);

    // nextElement.setFocus();
  };
  const selectAll = (el: any) => {
    // access to the native input element
    let nativeEl: HTMLInputElement = el.nativeElement.querySelector("input");

    if (nativeEl) {
      if (nativeEl.setSelectionRange) {
        // select the text from start to end
        return nativeEl.setSelectionRange(0, nativeEl.value.length);
      }

      nativeEl.select();
    }
  };

  useEffect(() => {
    console.log("isOpen ,field1>>", isOpen, field1);
    if (isOpen && field1) {
      field1?.current?.setFocus();
      // selectAll(field1?.current);
    }
  }, [isOpen, field1]);

  const resendOTP = () => {
    axiosInstance
      .post("/user/send-otp", {
        email,
      })
      .then((res: any) => {
        if ([200, 403].includes(res.data.status)) {
        }
      });
  };
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      cssClass="user-sign user-otp"
      // backdropDismiss={false}
    >
      <div className="at-center">
        <div className="at-bold size-28 at-center">EMAIL VERIFICATION</div>
        <br />
        <div>
          <span>
            Please enter the 4 digit code sent to <b>{email}</b>
          </span>
        </div>

        <div className="user-otp">
          <div className="user-otp-content">
            <IonInput
              ref={field1}
              maxlength={1}
              type="password"
              autofocus
              onFocus={() => (arrIndex.current = 0)}
              onKeyUp={gotoNextField}
              inputmode="numeric"
              // style={{ border: "1px solid blue", width: "100px" }}
            ></IonInput>
            <IonInput
              ref={field2}
              maxlength={1}
              type="password"
              onFocus={() => (arrIndex.current = 1)}
              onKeyUp={gotoNextField}
              inputmode="numeric"
              // style={{ border: "1px solid blue", width: "100px" }}
            ></IonInput>
            <IonInput
              ref={field3}
              maxlength={1}
              type="password"
              onFocus={() => (arrIndex.current = 2)}
              onKeyUp={gotoNextField}
              inputmode="numeric"
              // style={{ border: "1px solid blue", width: "100px" }}
            ></IonInput>
            <IonInput
              ref={field4}
              maxlength={1}
              type="password"
              onFocus={() => (arrIndex.current = 3)}
              onKeyUp={gotoNextField}
              inputmode="numeric"
              // style={{ border: "1px solid blue", width: "100px" }}
            ></IonInput>
          </div>
        </div>

        <div>
          {errorMessage && (
            <div className="at-center error-message">{errorMessage}</div>
          )}
          <span onClick={resendOTP} className="cursor-pointer">
            <u>RESEND OTP</u>
          </span>
        </div>

        {/* <IonIcon
          onClick={onClose}
          className="modal-close close cursor-pointer"
          icon={closeCircleOutline}
          size="large"
        />
        <img src="../img/checked.png" alt="" width="80" />
        */}
        {/* <div className='message'>{message}</div> */}
      </div>
    </IonModal>
  );
};

export default UserOTP;
