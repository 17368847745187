import { IonButton } from "@ionic/react";
import React, { useContext, useRef, useState } from "react";
import { PlatformContext } from "../contexts/PlatformContext";
// import MediaPlayer from "../utilities/MediaPlayer";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";

const PlayShuffle: React.FC<any> = ({ heroAlbum }) => {
  const { platform } = useContext(PlatformContext);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const refTrackNo = useRef(0);

  const randomizeTrackNo = (max: number) => {
    const min = 1;
    let rand = parseInt((min + Math.random() * (max - min)).toFixed(0)) - 1;
    // alert(rand+ ' '+ min+ ' '+ max);
    if (rand <= min) {
      rand = min;
    }
    // console.log("rand,min,max>>>.", rand, min, max);
    refTrackNo.current = rand;
  };

  const playMedia = () => {
    console.log("heroAlbum>>>>", heroAlbum.tracks);
    if (heroAlbum && heroAlbum.tracks) {
      randomizeTrackNo(heroAlbum.tracks.length - 1);
    }
    setShowMediaPlayer(true);
  };
  return (
    <>
      <div>
        <br />
        <img
          src={"./assets/img/shuffle.png"}
          width="40"
          className="icon_shuffle "
          alt="icon"
        />
        <div className="size-18 at-bold">Undecided on what to watch?</div>
        <div className="color-aaa pad-5-bottom">
          We'll shuffle everything on TakeOne and recommend music videos to you,
          based on your preferences.
        </div>
        <IonButton
          color="dark"
          className="size-12 color-aaa at-bold"
          onClick={playMedia}
          style={{ textTransform: "none" }}
        >
          <img
            src={"./assets/img/shuffle.png"}
            width="20"
            className="icon_shuffle "
            alt="icon"
          />{" "}
          &nbsp; Play Something
        </IonButton>
      </div>

      <div className={`floating-menu ${platform.name}`} onClick={playMedia}>
        <img
          alt=""
          src={"./assets/img/shuffle.png"}
          width="20"
          className="icon_shuffle"
        />
 
        {showMediaPlayer && (
          // <MediaPlayer
          //   tracks={heroAlbum.tracks}
          //   trackNo={refTrackNo.current}
          //   isOpen={showMediaPlayer}
          //   onClose={() => {
          //     setShowMediaPlayer(false);
          //   }}
          // />
          <ModalAvtPlayer
            playlist={heroAlbum.tracks}
            trackNo={refTrackNo.current}
            isOpen={showMediaPlayer}
            onClose={() => {
              setShowMediaPlayer(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default PlayShuffle;
