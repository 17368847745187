import { IonIcon, IonModal } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../styles/fileviewer.css";

const PdfViewer = ({
  isOpen,
  onClose,
  file,
}: {
  isOpen: any;
  onClose: any;
  file: any;
}) => {
  const onDismiss = () => {
    onClose(1);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(1);
//   const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      cssClass="modal-message modal-viewer"
    >
      <IonIcon
        onClick={onClose}
        className="close cursor-pointer"
        icon={closeCircle}
        size="large"
      />
      <div className="modal-content">
        <Document
          className="pdf-viewer"
          file={file}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={1000}
            />
          ))}
        </Document>
      </div>
    </IonModal>
  );
};

export default PdfViewer;
