import { Grid, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FileHeader } from "./FileHeader";

export interface SingleFileUploadWithProgressProps {
  folder: string;
  file: File;
  onDelete: (file: File) => void;
  onUpload: (file: File, url: string) => void;
}

export function SingleFileUploadWithProgress({
  folder,
  file,
  onDelete,
  onUpload,
}: SingleFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);

  // console.log('file>>>', file);

  useEffect(() => {
    async function upload() {
      // console.log('folder>>>>>>>>>>>>>',folder);
      
      const url = await uploadFile(folder, file, setProgress);
      onUpload(file, url);
    }

    upload();
  // }, [onUpload, folder, file]);
  // eslint-disable-next-line
  }, []);

  return (
    <Grid item>
      <FileHeader file={file} onDelete={onDelete} />
      <div style={{ position: "relative" }}>
        <LinearProgress variant="determinate" value={progress}/>
        <div
          style={{
            fontSize: "10px",
            color: "white",
            position: "absolute",
            top: "1px",
            left: `${progress / 2 - (progress / 2 < 5 ? 0 : 4)}%`,
          }}
        >
          {progress} %
        </div>
      </div>
    </Grid>
  );
}

function uploadFile(
  folder: string,
  file: File,
  onProgress: (percentage: number) => void
) {
  // const url = 'https://meekd.org/uploader';
  // const url = 'http://uploader.takeone.studio:5000/multiple';
  // const url = 'https://api.takeone.studio/upload';
  const url = 'https://api.takeone.tividad.com/upload';
  // const url = "http://localhost:5000/upload";
  // const key = 'docs_upload_example_us_preset';

  return new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      res(resp.secure_url);
    };
    xhr.onerror = (evt) => rej(evt);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    const formData = new FormData();
    // formData.append("name", "auric gavin");
    // formData.append("email", "avtividad@yahoo.com");
    // console.log('>>>>>>>>>>',folder)
    formData.append("folder", folder);
    formData.append("images", file);
    // formData.append('upload_preset', key);

    // console.log("formData>>>>", formData);

    xhr.send(formData);
  });
}

// function uploadFile_orig(file: File, onProgress: (percentage: number) => void) {
//   // const url = 'https://meekd.org/uploader';
//   const url = 'https://app.takeone.studio';
//   const key = 'docs_upload_example_us_preset';

//   return new Promise<string>((res, rej) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('POST', url);

//     xhr.onload = () => {
//       const resp = JSON.parse(xhr.responseText);
//       res(resp.secure_url);
//     };
//     xhr.onerror = (evt) => rej(evt);
//     xhr.upload.onprogress = (event) => {
//       if (event.lengthComputable) {
//         const percentage = (event.loaded / event.total) * 100;
//         onProgress(Math.round(percentage));
//       }
//     };

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('upload_preset', key);

//     xhr.send(formData);
//   });
// }
