import { FETCH_TRACKS, TRACK_SUCCESS,  } from '../actions/trackActionTypes'
 
const initialState = {
    items: [],
}

const TrackReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_TRACKS:
            return {
                ...state,
                items: action.payload
            }
        case TRACK_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export default TrackReducer;