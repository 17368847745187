import React from "react";
//--- import '../styles/404.css';

const NotFoundPage: React.FC<any> = () => {
  return (
    <div className="page-404">
    <div className="page-404-container">
      <canvas id="canvas" hidden></canvas>
      <div className="center">
        <h1>404</h1>

        <p>PAGE NOT FOUND.</p>
      </div>
    </div>
    </div>
  );
};

export default NotFoundPage;
