//-- https://capacitorjs.com/docs/apis/app
//-- https://capacitorjs.com/docs/guides/deep-links
//-- https://www.youtube.com/watch?v=DG7TMredeUs
//-- https://developers.google.com/digital-asset-links/tools/generator
//-- https://developer.android.com/training/app-links/verify-site-associations

//-- C:\Program Files\Android\Android Studio\jre\bin\keytool.exe        -- add to windows path
//-- keytool -genkey -v -keystore my-release-key.keystore -alias alias_name -keyalg RSA -keysize 2048 -validity 10000

import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // alert(" App.addListener");
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split(".studio").pop();
      if (slug) {
        history.push(slug);
        // alert(slug.includes("title="))
        if(slug.includes("title=")){
          window.location.reload();
        }
      }

      // If no match, do nothing - let regular routing
      // logic take over
    });

    ///////////////////
    // App.addListener("appStateChange", ({ isActive }) => {
    //   // console.log('App state changed. Is active?', isActive);
    //   alert("App state changed. Is active?" + isActive);
    // });

    // App.addListener("appUrlOpen", (data) => {
    //   // console.log('App opened with URL:', data);
    //   alert("App opened with URL:" + data);
    // });

    // App.addListener("appRestoredResult", (data) => {
    //   // console.log('Restored state:', data);
    //   alert("Restored state:" + data);
    // });

    // const checkAppLaunchUrl = async () => {
    //   // const { url } = await App.getLaunchUrl();

    //   alert("App opened with URL: " + "url");
    //   alert(window.location.href)
    // };
  }, []);

  return null;
};

export default AppUrlListener;
