import React, { useContext, useEffect, useRef, useState } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import { MediaInfo, Track } from "../definitions/MediaInterface";
import { PlatformContext } from "../contexts/PlatformContext";
// import { ImageTrack } from "../utilities/MediaImages";
// import InfoModal from "./InfoModal";
import MediaPlayer from "../utilities/MediaPlayer";
import InfoModal from "./InfoModal";
import axios from "axios";

const Row1e: React.FC<any> = ({ heroAlbum, slideOpts, isTall }) => {
  const { platform } = useContext(PlatformContext);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [mediaInfo, setMediaInfo] = useState<MediaInfo>();
  const [country, setCountry] = useState("");
  const refTrackNo = useRef(0);

  useEffect(() => {
    const getGeoInfo = () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response: any) => {
          let data = response.data;
          // alert(data.country_name);
          setCountry(data.country_name);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getGeoInfo();
  }, []);

  const showInfo = (index: number) => {
    refTrackNo.current = index;
    // console.log('heroAlbum>>>>', index, heroAlbum.tracks[index]);
    const track = heroAlbum.tracks[index];
    // console.log('heroAlbum>>>>', index, heroAlbum.tracks[index]);
    // console.log('track.album>>>>', track.album);
    // console.log("heroAlbum >>>>", heroAlbum.tracks);

    if (track) {
      const artist = track?.artists
        ?.map((artist: any) => {
          return artist.name;
        })
        .join(", ");
      setMediaInfo({
        // albumIndex: track.album,
        albumIndex: 0,
        category: "TRACK",
        name: track.name,
        description: track.description,
        name_ar: track.name_ar,
        description_ar: track.description_ar,
        // image: ImageAlbum(heroAlbum?.id, platform.name),
        // image: ImageTrack(track.id, platform.name),
        image: track.image,
        trackNo: index,
        // artist: track?.artists[0]?.name,
        artist,
      });
      setShowInfoModal(true);
      // setTracks(createTracks(album_active.tracks))
    }
  };

  // const playMedia = (index: number) => {
  //   refTrackNo.current = index;
  //   // alert("refTrackNo.current>>>" + refTrackNo.current);
  //   setShowMediaPlayer(true);
  // };

  return (
    <div>
      <br />
      <div className="size-18 at-bold">Top 10 in {country}</div>

      {/* {console.log('heroAlbum>>>', heroAlbum)} */}
      {heroAlbum && (
        <IonSlides
          options={slideOpts}
          key={heroAlbum.id}
          style={{ marginLeft: "20px" }}
        >
          {heroAlbum?.tracks &&
            heroAlbum?.tracks
              ?.slice(0, 10)
              ?.map((track: Track, idx: number) => {
                return (
                  <IonSlide
                    key={idx}
                    // className={`${
                    //   platform.name === "web" ? "slide-wide-number" : "slide-default-number"
                    // }  ${platform.name}`}

                    className={`slide-default-number slide-default ${platform.name}`}
                    onClick={() => showInfo(idx)}
                    // onClick={() => playMedia(idx)}
                    style={{
                      // backgroundImage: `url(${
                      //   track?.image
                      //     ? track?.image.replaceAll(
                      //         ".jpg",
                      //         `-en${isTall ? "-tall" : ""}.jpg`
                      //       )
                      //     : "./assets/img/takeone-logo-gray.png"
                      // })`,
                      // backgroundSize: "cover",
                      // border: "1px solid #111",
                      height: "190px",
                      ////// zIndex: `${10 - idx}`,
                    }}
                    // dir={`${track.name_ar ? 'rtl' : 'auto'}`}
                  >
                    <div
                      style={{
                        background: "#000",
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${
                          track?.image
                            ? track?.image.replaceAll(
                                ".jpg",
                                `-en${isTall ? "-tall" : ""}.jpg`
                              )
                            : "./assets/img/takeone-logo-gray.png"
                        })`,
                        backgroundSize: "cover",
                        border: "1px solid #111",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div
                      className="track-number hollow-text"
                      // style={{ zIndex: `${10 - idx}` }}
                    >
                      {idx + 1}
                    </div>

                    {/* <div className="track-name">{track?.name} </div>
                    <div className="track-artists">
                      {track?.artists[0]?.name}{" "}
                    </div> */}
                  </IonSlide>
                );
              })}
        </IonSlides>
      )}
      {showInfoModal && (
        <InfoModal
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
          albums={[heroAlbum]}
          trackNo={refTrackNo.current}
          mediaInfo={mediaInfo}
          playlist={heroAlbum}
        />
      )}
      {showMediaPlayer && (
        <MediaPlayer
          tracks={heroAlbum.tracks}
          trackNo={refTrackNo.current}
          isOpen={showMediaPlayer}
          onClose={() => {
            setShowMediaPlayer(false);
          }}
        />
      )}
    </div>
  );
};

export default Row1e;
