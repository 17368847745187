import { FETCH_ALBUMS, ALBUM_SUCCESS, GENRE_ALBUM_SUCCESS, FETCH_GENRE_ALBUMS,  } from '../actions/albumActionTypes'
 
const initialState = {
    items: [],
    item: {}
}

export const AlbumReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALBUMS:
            return {
                ...state,
                items: action.payload
            }
        case ALBUM_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export const AlbumArtistReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALBUMS:
            return {
                ...state,
                items: action.payload
            }
        case ALBUM_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export const AlbumGenreReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_GENRE_ALBUMS:
            return {
                ...state,
                items: action.payload
            }
        case GENRE_ALBUM_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }
}

// export default AlbumReducer;