export interface Genre {
  name: string;
  type: string;
  image: string;
}

export interface Artist {
  id: number;
  age: number;
  bio: string;
  bio_ar: string;
  city: string;
  company: string;
  country: string;
  created_at: string;
  dob: string;
  genres: Genre[];
  image: string;
  image_field: string;
  name: string;
  name_ar: string;
  occupation: string;
  occupation_ar: string;
  status: string;
}

export interface MediaTrack {
  id: number;
  name: string;
  name_ar: string;
  artist: string;
  duration: string;
  description: string;
  description_ar: string;
  sources: [
    {
      src: string;
      type: string;
      res: string;
      label: string;
      default: boolean;
    }
  ];
  image: string;
  poster: string;
  status: MEDIA_STATUS;
  downloadProgress: number;
  artists: any;
}

export interface Track {
  id: number;
  artists: Artist[];
  audio: string;
  image: string;
  video: string;
  duration: string;
  file_type: string;
  genres: Genre[];
  origin_city: string;
  origin_country: string;
  other_platforms_urls: string;
  release_date: string;
  status: string;
  title: string;
  title_ar: string;
  name: string;
  description: string;
  name_ar: string;
  description_ar: string;
}

export interface Album {
  album_image: string;
  album_name: string;
  album_name_ar: string;
  album_info: string;
  album_info_ar: string;
  created_at: string;
  id: number;
  no_of_downloads: number;
  no_of_hicreated_at: number;
  origin_city: string;
  origin_country: string;
  release_date: string;
  status: string;
  artists: Artist[];
  tracks: Track[];
}

export interface Sources {
  src: string;
  type: string;
  res: string;
  label: string;
}

export enum MEDIA_STATUS {
  DEFAULT,
  DOWNLOADING,
  DOWNLOADED,
}

export interface Playlist {
  id: number;
  name: string;
  description: string;
  name_ar: string;
  description_ar: string;
  artist: string;
  artists: Artist[],
  sources: Sources[];
  // image: string;
  poster: string;
  status: MEDIA_STATUS;
  duration: string;
  downloadProgress: number;
  segmentNo: number;
  segmentCount: number;
  currentTime: number;
  // currentTime:any,
  // playlistItemId_: any
}

export interface User {
  item: {
    id: number;
    username: string;
    email: string;
    fullname: string;
    age: string;
    country: string;
    gender: string;
    mobileno: string;
    photo: string;
  };
}

export interface Doc {
  item: {
    folder: string;
    files: object;
  };
}

// export enum RESULT_CATEGORY { ALBUM, TRACK, ARTIST };
export interface MediaInfo {
  albumIndex: number;
  // category: RESULT_CATEGORY,
  category: string;
  image: string;
  trackNo: number;
  name: string;
  description: string;
  name_ar: string;
  description_ar: string;
  artist: string;
}

export interface AlbumTracks {
  album_image: string;
  album_info: string;
  album_name: string;
  album_name_ar: string;
  id: number;
  no_of_downloads: number;
  // no_of_hicreated_at: number;
  release_date: string;
  status: string;
  // artists: Artist[];
  tracks: Track[];
}
