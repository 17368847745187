import React, { useContext, useEffect, useRef, useState } from "react";
import { File } from "@ionic-native/file";
import axios from "axios";
import * as rax from "retry-axios";
import {
  MediaTrack,
  MEDIA_STATUS,
  Playlist,
} from "../definitions/MediaInterface";

// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import '../styles/circular-progressbar-custom.css';

import { IonIcon } from "@ionic/react";
import {
  archive,
  checkboxOutline,
  download,
  downloadOutline,
  play,
} from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import { PlatformContext } from "../contexts/PlatformContext";
import { LocalPlaylistContext } from "../contexts/LocalPlaylistContext";
// import MediaPlayer from "./MediaPlayer";
import ModalAvtPlayer from "./ModalAvtPlayer";
import "../styles/progress-pie.css";

const axiosConfig: any = {
  responseType: "blob",
  raxConfig: {
    retry: 25000, // number of retry when facing 4xx or 5xx
    // retryDelay: 1000, // Milliseconds to delay at first.  Defaults to 100. Only considered when backoffType is 'static'
    // backoffType: 'static',
    backoffType: "exponential",
    noResponseRetries: 25000, // number of retry when facing connection error
    onRetryAttempt: (err: any) => {
      const cfg: any = rax.getConfig(err);
      console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
    },
  },
  // timeout: 60000,
};

const MediaDownload: React.FC<any> = ({
  track,
  isButton = false,
  showLabel = true,
}: {
  track: MediaTrack;
  isButton: boolean;
  showLabel: boolean;
}) => {
  const { platform } = useContext(PlatformContext);
  const [progress, setProgress] = useState(0);
  const { playlist, setPlaylist, setUpdatePlaylist, getPlaylist } =
    useContext(LocalPlaylistContext);
  const [showMediaPlayer, setShowMediaPlayer] = useState({ isOpen: false });
  const refStatus = useRef(MEDIA_STATUS.DEFAULT);

  const MAX_FILES = 3;
  // const MAX_FILES = 1;

  const getDownloadProgress = () => {
    if (track && platform.name !== "web") {
      // updateTracks();
      let downloadProgress = progress;
      const pl = playlist?.filter((pl: Playlist) => {
        return pl.id === track.id;
      });
      if (pl.length > 0) {
        downloadProgress = pl[0].downloadProgress;
        refStatus.current = pl[0].status;
      }

      // console.log('MediaDownload - playlist>>>', playlist);
      // console.log('MediaDownload - track>>>', track);
      // console.log('MediaDownload - downloadProgress>>>', downloadProgress);

      if (downloadProgress) setProgress(downloadProgress);
    }
  };

  useEffect(() => {
    getDownloadProgress();
  }, []);

  useEffect(() => {
    getDownloadProgress();
  }, [playlist]);

  useEffect(() => {
    getPlaylist();
    // console.log("track====>>>", track, playlist);

    if (track) {
      refStatus.current = track.status;
      setProgress(track.downloadProgress);
    } else {
      setProgress(0);
    }
  }, [track]);

  const getSegmentCount = (url: string, parentFolder: string) => {
    let segmentCount = 0;
    const filename = url?.replace(/^.*[\\\/]/, "");
    return axios
      .get(url)
      .then((response) => {
        File.writeFile(parentFolder, filename, new Blob([response.data]), {
          replace: true,
        });

        const textVal = response.data.split("\n");
        for (const number of textVal.reverse()) {
          if (number.includes("segment")) {
            segmentCount =
              parseInt(
                "0" +
                  number
                    .toLowerCase()
                    .replaceAll("segment", "")
                    .replaceAll(".ts", "")
              ) + 1;
            break;
          }
        }
        return segmentCount;
      })
      .catch((err: any) => {
        console.log("getSegmentCount -err>>>", err);
      });
  };

  const saveFiles = (
    responses: any,
    parentFolder: string,
    ctr: number,
    segmentCount: number
  ) => {
    // alert("saveFiles>>> " + ctr + " - " + parentFolder);

    responses.map((res: any, index: number) => {
      const urlPath = res.config.url.split("/");
      const filename = urlPath[urlPath.length - 1];

      const ext = filename.split(".").pop();

      //// if (!['.ts', '.m3u8'].includes('.' + ext)) { parentFolder = parentFolder.replace('/v720p', '') }
      if (![".ts", ".m3u8"].includes("." + ext)) {
        parentFolder = parentFolder?.replace("/video", "");
      }

      File.writeFile(parentFolder, filename, new Blob([res.data]), {
        replace: true,
      })
        .then(() => {
          if (parentFolder.includes("/video")) {
            let currentProgress = Math.round(((ctr + 1) * 100) / segmentCount);
            setProgress(currentProgress);

            // alert('currentProgress>>>' + currentProgress + ' | ' + parentFolder + ' | ' + filename + ' | ' + ctr);

            updatePlaylist(parentFolder, ctr, segmentCount);
            if (currentProgress >= 100) {
              refStatus.current = MEDIA_STATUS.DOWNLOADED;
              // addTrack(parentFolder, ctr, segmentCount);
              updatePlaylist(parentFolder, ctr, segmentCount);

              //---disabled 2021-12-21 - no auto-play after video download has been completed
              ////////////////////////---- setShowMediaPlayer({ isOpen: true });
            }
            ctr += 1;
          }
        })
        .catch((err) => {
          console.log(err, `Error saving file ${filename}`);
        });
    });
  };

  const updatePlaylist = (
    parentFolder: string,
    segmentNo: number,
    segmentCount: number
  ) => {
    const currentPlaylist: Playlist[] = playlist;
    const downloadProgress = Math.round(((segmentNo + 1) * 100) / segmentCount);
    const plIndex = playlist.findIndex((pl: Playlist) => {
      return pl.id === track.id;
    });

    const currentTrack: Playlist = {
      id: track.id,
      name: track.name,
      name_ar: track.name_ar,
      description: track.description,
      description_ar: track.description_ar,
      artists: track.artists,
      artist: track.artists.map((artist: any) => artist.name).join(", "),
      duration: track.duration,
      status:
        downloadProgress >= 100
          ? MEDIA_STATUS.DOWNLOADED
          : MEDIA_STATUS.DOWNLOADING,
      poster: Capacitor.convertFileSrc(
        parentFolder?.replace("audio", "").replace("video", "") +
          track?.image?.replace(/^.*[\\\/]/, "")
      ),
      downloadProgress,
      segmentNo,
      segmentCount,
      currentTime: 0,
      sources: [
        {
          src: Capacitor.convertFileSrc(parentFolder + "/index.m3u8"),
          type: "application/x-mpegURL",
          res: "720",
          label: "720",
        },
      ],
    };
    // console.log('playlist >>>', playlist)
    // console.log('playlist  filter>>>', track.id, playlist.findIndex((pl: Playlist) => { return pl.id === track.id }))

    if (plIndex >= 0) {
      currentPlaylist[plIndex] = currentTrack;
    } else {
      currentPlaylist.push(currentTrack);
    }
    setPlaylist(currentPlaylist);
    setUpdatePlaylist(true);

    // >>>downloadProgress
  };

  const addTrack = (
    parentFolder: string,
    segmentNo: number,
    segmentCount: number
  ) => {
    const data: Playlist[] = playlist;

    const currentTrack: Playlist = {
      id: track.id,
      name: track.name,
      name_ar: track.name_ar,
      description: track.description,
      description_ar: track.description_ar,
      artists: track.artists,
      artist: track.artists.map((artist: any) => artist.name).join(", "),
      duration: track.duration,
      status: MEDIA_STATUS.DOWNLOADED,
      poster: Capacitor.convertFileSrc(
        parentFolder?.replace("audio", "").replace("video", "") +
          track?.image?.replace(/^.*[\\\/]/, "")
      ),
      downloadProgress: 100,
      segmentNo,
      segmentCount,
      currentTime: 0,
      sources: [
        {
          src: Capacitor.convertFileSrc(parentFolder + "/index.m3u8"),
          type: "application/x-mpegURL",
          res: "720",
          label: "720",
        },
      ],
    };

    // currentTrack.status = MEDIA_STATUS.DOWNLOADED;
    // currentTrack.sources[0].src = Capacitor.convertFileSrc(parentFolder + '/index.m3u8');
    // currentTrack.poster = Capacitor.convertFileSrc(parentFolder.replace('audio', '').replace('video', '') + currentTrack.poster.replace(/^.*[\\\/]/, ''));
    // // currentTrack.currentTime = 0;

    // console.log('track===>>>>', track.artists)
    // console.log('data>>>>', data)
    // console.log('currentTrack>>>>', currentTrack)

    data.push(currentTrack);
    setPlaylist(data);
    setUpdatePlaylist(true);
  };

  const recursiveDownloadAll = (
    parentUrl: string,
    parentFolder: string,
    ctr: number,
    segmentCount: number
  ) => {
    let requests: any = [];

    Array(MAX_FILES)
      .fill(0)
      .map((_, i) => {
        if (i + ctr < segmentCount) {
          requests.push(
            axios.get(`${parentUrl}segment${i + ctr}.ts`, axiosConfig)
          );
        }
      });

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          saveFiles(responses, parentFolder, ctr, segmentCount);
        })
      )
      .then(() => {
        // alert("ctr + MAX_FILES >>"+ ctr +' | '+ MAX_FILES);
        if (ctr + MAX_FILES <= segmentCount) {
          recursiveDownloadAll(
            parentUrl,
            parentFolder,
            ctr + MAX_FILES,
            segmentCount
          );
        }
      })
      .catch((err: any) => {
        console.log(
          "recursiveDownloadAll - ctr, segmentCount, err>>>",
          ctr,
          segmentCount,
          err
        );
        recursiveDownloadAll(parentUrl, parentFolder, ctr - 2, segmentCount);
      });
  };

  const downloadAudioFiles = (parentUrl: string, parentFolder: string) => {
    const url = parentUrl + "audio0/index.m3u8";
    getSegmentCount(url, parentFolder + "/audio").then((segmentCount: any) => {
      // setProgress(1);
      recursiveDownloadAll(
        parentUrl + "audio0/",
        parentFolder + "/audio",
        0,
        segmentCount
      );
    });
  };

  const downloadVideoFiles = (parentUrl: string, parentFolder: string) => {
    const url = parentUrl + "v720p/index.m3u8";
    //// getSegmentCount(url, parentFolder + '/v720p')
    getSegmentCount(url, parentFolder + "/video").then((segmentCount: any) => {
      setProgress(1);
      recursiveDownloadAll(
        parentUrl + "v720p/",
        parentFolder + "/video",
        0,
        segmentCount
      );
    });
  };

  const downloadCommon = (url: string, folderName: string) => {
    // console.log("downloadCommon url>>>", url);
    // console.log(
    //   "platform.downloadPath + folderName>>>",
    //   platform.name,
    //   platform.downloadPath,
    //   folderName
    // );

    const parentUrl = url?.replace("master.m3u8", "");
    // const posterUrl = track?.poster;
    const posterUrl = track?.image;
    const filename = posterUrl?.replace(/^.*[\\\/]/, "");

    // console.log('track>>>>>', track);
    // console.log('posterUrl>>>>>', posterUrl);
    // console.log('platform.downloadPath>>>>>', platform.downloadPath + folderName, filename,);

    // axios.get(track.poster, axiosConfig).then((response) => {
    axios
      .get(track.image, axiosConfig)
      .then((response) => {
        File.writeFile(
          platform.downloadPath + folderName,
          filename,
          new Blob([response.data]),
          { replace: true }
        );
      })
      .catch((err: any) => {
        console.log("downloadCommon -err>>>", err);
      });

    File.createDir(platform.downloadPath + folderName, "audio", false)
      .then(() => {
        downloadAudioFiles(parentUrl, platform.downloadPath + folderName);
      })
      .catch((error) => {
        downloadVideoFiles(parentUrl, platform.downloadPath + folderName);
      });

    File.createDir(platform.downloadPath + folderName, "video", false)
      .then(() => {
        downloadVideoFiles(parentUrl, platform.downloadPath + folderName);
      })
      .catch((error) => {
        downloadVideoFiles(parentUrl, platform.downloadPath + folderName);
      });
  };

  const downloadMediaFiles = (url: string) => {
    // console.log("downloadMediaFiles>>>", url, refStatus.current);

    url = url?.replace("-preview.m3u8", ".m3u8");
    if (refStatus.current !== MEDIA_STATUS.DEFAULT) {
      return false;
    }

    setProgress(1);
    refStatus.current = MEDIA_STATUS.DOWNLOADING;

    const urlPath = url.split("/");
    const folderName = urlPath[urlPath.length - 2];
    try {
      File.createDir(platform.downloadPath, folderName, false)
        .then(() => {
          downloadCommon(url, folderName);
        })
        .catch((err) => {
          downloadCommon(url, folderName);
        });
    } catch (error) {}
  };

  const BoxComponent = (props: any) => {
    // console.log("track>>>", track);
    return props.isButton ? (
      <>
        <button
          className="at-button at-center cursor-pointer"
          color="secondary"
          style={{ textTransform: "none", background: "transparnt" }}
          onClick={() => downloadMediaFiles(track?.sources[0].src)}
        >
          {props.children} {props.text}
        </button>
      </>
    ) : (
      <span className="cursor-pointer">{props.children}</span>
    );
  };

  const downloadIcon = () => {
    switch (refStatus.current) {
      case MEDIA_STATUS.DOWNLOADING:
        return (
          <BoxComponent isButton={isButton} text="&nbsp; Downloading">
            <div className="size-20 x-pad-5-tb" style={{ padding: "3.8px 0" }}>
              <span
                className="pie"
                data-value={progress}
                style={{ width: "16.5px", height: "16.5px" }}
              ></span>
            </div>
            {showLabel && !isButton && (
              // <div className="size-12 color-666">Downloading</div>
              <div className="size-12 color-666 at-flex-center-start">Downloading</div>
            )}
            {/* <span className="pie" data-value={progress} style={{ width: '20px', height: '20px' }} ></span> */}
          </BoxComponent>
        );

      case MEDIA_STATUS.DOWNLOADED:
        return (
          <BoxComponent isButton={isButton} text="&nbsp;Downloaded">
            {/* <IonIcon icon={checkboxOutline} className="size-20 pad-5-tb" /> */}
            <IonIcon icon={checkboxOutline} className="cursor-pointer size-30 at-flex-center-end" />
            {showLabel && !isButton && (
              // <div className="size-12 color-666">Downloaded</div>
              <div className="size-12 color-666 at-flex-center-start">Downloaded</div>
            )}
          </BoxComponent>
        );

      default:
        return (
          <BoxComponent isButton={isButton} text="&nbsp;Download">
            <IonIcon
              icon={downloadOutline}
              // className="size-20 pad-5-tb"
              className="cursor-pointer size-30 at-flex-center-end"
              onClick={() => downloadMediaFiles(track?.sources[0].src)}
            />
            {showLabel && !isButton && (
              <div className="size-12 color-666">Download</div>
            )}
          </BoxComponent>
        );
    }
  };

  return (
    <>
      {/* <button
        onClick={() => updatePlaylist('parentFolder', 2, 25)}
      >test download</button> */}
      {downloadIcon()}

      {/* {console.log("playlist>>>>>>>>>", playlist)}
      {console.log("downloadCommon track>>>", track)} */}

      {/* {console.log("downloadCommon track>>>", track)}  */}

      {showMediaPlayer.isOpen && playlist && (
        // <MediaPlayer
        //   tracks={playlist}
        //   trackNo={playlist.length - 1}
        //   isOpen={showMediaPlayer.isOpen}
        //   onClose={() => setShowMediaPlayer({ isOpen: false })}
        //   showPlayerLoader={false}
        // />
        <ModalAvtPlayer
          playlist={playlist}
          trackNo={playlist.length - 1}
          isOpen={showMediaPlayer.isOpen}
          onClose={() => setShowMediaPlayer({ isOpen: false })}
        />
      )}

      {/* {console.log("playlist >>>>>>>", playlist)} */}

      {/* {console.log("MediaDownload platform >>>>>>>", platform)} */}
      {/* {console.log("MediaDownload - progress, refStatus.current>>>",
        progress,
        refStatus.current
      )} */}
    </>
  );
};

export default MediaDownload;
