import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import React, { useState } from 'react'

const Releases = () => {
  const [showModalFromRight, setShowModalFromRight] = useState(false)
  const [showModalFromBottom, setShowModalFromBottom] = useState(false)
  return (
    <>
      {/* <IonHeader className="top-header ion-no-border" >
        <IonToolbar>
          <IonTitle>Releases</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <br />
        <br />
        <br />
        <div className='pad-10'>

          Coming soon...
        </div>
        {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas saepe vel totam quaerat, eveniet delectus dicta voluptatem eaque nostrum optio tenetur, laborum magnam temporibus hic voluptate, iste est perspiciatis deserunt!
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas saepe vel totam quaerat, eveniet delectus dicta voluptatem eaque nostrum optio tenetur, laborum magnam temporibus hic voluptate, iste est perspiciatis deserunt! */}
      </IonContent>

    </>
  )
}

export default Releases
