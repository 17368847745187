import { Grid, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { SingleFileUploadWithProgress } from "./SingleFileUploadWithProgress";
import { UploadError } from "./UploadError";

let currentId = 0;

function getNewId() {
  // we could use a fancier solution instead of a sequential ID :)
  return ++currentId;
}

export interface UploadableFile {
  id: number;
  file: File;
  errors: FileError[];
  url?: string;
}

const useStyles = makeStyles((theme: any) => ({
  dropzone: {
    // border: `2px dashed ${theme.palette.primary.main}`,
    // borderRadius: theme.shape.borderRadius,
    // borderRadius: '50%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    // height: theme.spacing(10),
    height: "240px",
    outline: "none",
    backgroundColor: "transparent",
    // backgroundImage: "https://uploader.app.takeone.studio/images/upload.png",
  },
}));

// const MediaUploader: React.FC<any> = ({folder, acceptedFiles=['image/*', '.mp3', 'video/mp4', '.pdf', '.doc', '.docx', '.zip']}) => {
const MediaUploader: React.FC<any> = ({
  folder,
  maxFiles,
  minFiles,
  acceptedFiles,
  title = "",
  showModalSuccess,
  class_name = "",
  buttonCaption = "SUBMIT",
}) => {
  const classes = useStyles();

  const [files, setFiles] = useState<UploadableFile[]>([]);
  const refUploadedFiles = useRef<any>([]);
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedAcc = accFiles.map((file) => ({
      file,
      errors: [],
      id: getNewId(),
    }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);

    // console.log('accept>>>>>', acceptedFiles)
    // accept=['image/*', '.mp3', 'video/mp4', '.pdf', '.doc', '.docx', '.zip'];
  }, []);

  function onUpload(file: File, url: string) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
  }

  function onDelete(file: File) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: ["image/*", "video/mp4", ".pdf", ".doc", ".docx"],
    // accept: ['image/*', '.mp3', 'video/mp4', '.pdf', '.doc', '.docx', '.zip'],
    accept: acceptedFiles,
    // accept:{acceptedFiles},
    // accept: ['image/*',   '.pdf', '.doc', '.docx'],
    //////// maxSize: 300 * 1024, // 300KB
    maxFiles,
  });

  useEffect(() => {
    files.map((fileWrapper: any) => {
      if (
        fileWrapper.errors.length <= 0 &&
        !refUploadedFiles.current.includes(fileWrapper.file.name)
      ) {
        refUploadedFiles.current.push(fileWrapper.file.name);
        // console.log(" refUploadedFiles.current>>>", refUploadedFiles.current);
      }
      return true;
    });
  }, [files]);
  return (
    <React.Fragment>
      {maxFiles && refUploadedFiles.current.length >= maxFiles ? (
        <></>
      ) : (
        <div
          {...getRootProps({
            className: classes.dropzone + ` media-uploader  ${class_name}`,
          })}
        >
          <input
            disabled={maxFiles && refUploadedFiles.current.length >= maxFiles}
            {...getInputProps()}
          />

          {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
          {/* <span>Click Here</span> */}
        </div>
      )}

      <div className="upload-title">{title}</div>

      {files.length > 0 && (
        <div className="files-box">
          {files.map((fileWrapper) => (
            <Grid item key={fileWrapper.id}>
              {fileWrapper.errors.length ? (
                <UploadError
                  file={fileWrapper.file}
                  errors={fileWrapper.errors}
                  onDelete={onDelete}
                />
              ) : (
                <SingleFileUploadWithProgress
                  onDelete={onDelete}
                  onUpload={onUpload}
                  file={fileWrapper.file}
                  folder={folder}
                />
              )}
            </Grid>
          ))}
        </div>
      )}
      {((minFiles && refUploadedFiles.current.length >= minFiles) ||
        (maxFiles && refUploadedFiles.current.length >= maxFiles)) && (
        <div className="next_button submit_button">
          <br />
          <br />
          <button
            type="button"
            name="contract"
            className="next_arr"
            onClick={() => showModalSuccess(refUploadedFiles.current)}
          >
            {buttonCaption}
            <img src="../img/right-arrow.png" alt="" />
          </button>
        </div>
      )}

      {/* {modalSuccess} */}
    </React.Fragment>
  );
};
export default MediaUploader;
