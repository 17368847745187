import { FETCH_GENRES, GENRE_SUCCESS,  } from '../actions/genreActionTypes'
 
const initialState = {
    items: [],
    item: {}
}

const GenreReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_GENRES:
            return {
                ...state,
                items: action.payload
            }
        case GENRE_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export default GenreReducer;