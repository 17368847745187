export const slideOpts = {
  initialSlide: 0,
  // slidesPerView: 5,
  slidesPerView: 'auto',
  speed: 400,
  // speed: 200,
  // loop:true,
  freeMode:true,
};


export const initialTracks = () => {
  // console.log(Array.from({ length: 10 }, (v, i) => i))
  let arr: any = []
  Array.from({ length: 10 }, (v, i) =>
    arr.push(
      {
        id: 0, name: '', description: '', duration: '', poster: '', status: 0, genre: '',
        sources: [{ src: '', type: "application/x-mpegURL", res: '1080', label: '1080', default: true },],
        artists: [{ id: 0, image: '', name: '', occupation: '' }],
      }
    )
  )
  return arr;
}

export const initialAlbumTracks: any = [
  {
    album_name: '',
    album_image: '',
    album_info: '',
    tracks: initialTracks()
  }
]
