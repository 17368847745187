import React, { useContext, useEffect, useRef, useState } from "react";
import {
  createAnimation,
  IonHeader,
  IonIcon,
  IonModal,
  // IonButton,
  // IonTitle,
  // IonToolbar,
  useIonToast,
} from "@ionic/react";
import {
  // chevronForwardOutline,
  // downloadOutline,
  // informationCircleOutline,
  // play,
  // checkmark,
  closeOutline,
  playCircle,
  playOutline,
  checkmarkOutline,
  addOutline,
} from "ionicons/icons";
// import PlaylistModal from "./PlaylistModal";
// import MediaPlayer from "../utilities/MediaPlayer";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";

import MediaDownload from "../utilities/MediaDownload";
import { PlatformContext } from "../contexts/PlatformContext";
import { LocalPlaylistContext } from "../contexts/LocalPlaylistContext";
import UserAccessModal from "./UserAccessModal";
// import { createTracks } from '../utilities';
// import { Track } from '../definitions/MediaInterface';

const ModalFromBottom: React.FC<any> = ({
  isOpen,
  onClose,
  albums,
  trackNo,
  mediaInfo,
  playlist,
  isMyList = false,
  // platformName = "web",
}) => {
  const [modalUserAccess, setModalUserAccess] = useState({ isOpen: false });
  // const [showPlaylistModal, setShowPlaylistModal] = useState({ isOpen: false });
  const [showMediaPlayer, setShowMediaPlayer] = useState(false);
  // eslint-disable-next-line
  const [tracks, setTracks] = useState<any>();
  const refMediaPlayerTrackNo = useRef(0);
  const { platform } = useContext(PlatformContext);
  const [isOnMyList, setIsOnMyList] = useState<boolean>(false);
  const [isWeb, setIsWeb] = useState<boolean>(false);
  const { mylist, setMylist, setUpdateMylist, updateMylistData } =
    useContext(LocalPlaylistContext);
  // const [currentTrack, setCurrentTrack] = useState<any>();
  const [present] = useIonToast();

  useEffect(() => {
    // setIsWeb(["ios", "web"].includes(platform.name));
    setIsWeb(["web"].includes(platform.name));
    //---- setCurrentTrack(albums[0].tracks[trackNo]);
    checkMyList();
  }, []);

  const onDismiss = () => {
    onClose(1);
  };

  const enterAnimation = (baseEl: any) => {
    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector(".modal-wrapper")!)
      .fromTo("transform", "translateY(100vh)", "translateY(57vh)")
      .fromTo("opacity", ".8", "1");

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(300)
      .addAnimation([wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: any) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  // const playMedia = (isPreview: boolean = false) => {
  //   console.log('albums>>>',albums)
  //   // let newTracks = createTracks(albums[mediaInfo.albumIndex].tracks);
  //   let newTracks = albums[mediaInfo.albumIndex].tracks;
  //   if (albums?.length === 1) {
  //     const track = newTracks[trackNo];
  //     refMediaPlayerTrackNo.current = 0;
  //     if (isPreview) {
  //       track.sources[0].src = track.sources[0].src.replace('.m3u8', '-preview.m3u8')
  //     }
  //     setTracks([track]);
  //   } else {
  //     refMediaPlayerTrackNo.current = trackNo;
  //     setTracks(newTracks);
  //   }
  //   setShowMediaPlayer(true)
  // }
  const playMedia = (isPreview: boolean = false) => {
    // console.log('albums>>>', albums)
    if (albums?.length === 1) {
      refMediaPlayerTrackNo.current = 0;
      const track = albums[0].tracks[trackNo];
      if (isPreview) {
        track.sources[0].src = track.sources[0].src.replace(
          "master.m3u8",
          "master-preview.m3u8"
        );
      } else {
        track.sources[0].src = track.sources[0].src.replace(
          "-preview.m3u8",
          ".m3u8"
        );
      }
      setTracks([track]);
    } else {
      refMediaPlayerTrackNo.current = trackNo;
      setTracks(albums.tracks);
    }
    setShowMediaPlayer(true);
  };

  const checkMyList = () => {
    // console.log('mylist>>>', albums[0]?.tracks[trackNo]?.id, mylist,)
    // console.log("mylist>>>", mylist);
    // console.log("playlist[0]>>>", playlist?.tracks[trackNo]);
    // console.log("albums[0]>>>", albums[0]);
    // console.log("playlist.tracks[trackNo]>>>", playlist.tracks[trackNo].id);

    // if (platform.name !== "web" && playlist && mylist) {
    // if (platform.name !== "web" && albums && mylist) {
    if (albums && mylist) {
      // const trackId = albums[0]?.tracks[trackNo]?.id;
      const trackId = playlist?.tracks[trackNo]?.id;
      const index = mylist?.findIndex((list: any) => list.id === trackId);

      setIsOnMyList(index >= 0);
      return index >= 0;
    }
    return false;
  };

  // const updateMyList = () => {
  //   // alert("InfoModal");
  //   // console.log("currentTrack>>>>", currentTrack, playlist.tracks);

  //   const currentTrack = playlist.tracks[trackNo];
  //   if (currentTrack) {
  //     const isAdd = !isOnMyList;
  //     if (!checkMyList()) {
  //       const newMylist = mylist.slice();
  //       newMylist.push(currentTrack);

  //       setMylist(newMylist);
  //     } else {
  //       if (!isAdd) {
  //         const newMylist = mylist.filter((t: any) => t !== currentTrack);
  //         setMylist(newMylist);
  //         if (isMyList) {
  //           onDismiss();
  //         }
  //       }
  //     }
  //     console.log('currentTrack>>>>', currentTrack.id)
  //     setUpdateMylist(isAdd);
  //     updateMylistData(currentTrack.id, isAdd);

  //     present({
  //       duration: 2000,
  //       // buttons: [{ text: 'hide', handler: () => dismiss() }],
  //       message: `${isOnMyList ? "Removed from My List" : "Added to My List"}`,
  //       onDidDismiss: () => console.log("dismissed"),
  //       onWillDismiss: () => console.log("will dismiss"),
  //     });
  //     setIsOnMyList(isAdd);
  //   }
  // };
  const updateMyList = () => {
    // alert("InfoModal");
    // console.log("currentTrack>>>>", currentTrack, playlist.tracks);
    const user_id = localStorage.getItem("uid");
    if (!user_id) {
      setModalUserAccess({ isOpen: true });
      return false;
    }

    const currentTrack = playlist.tracks[trackNo];
    if (currentTrack) {
      const isAdd = !isOnMyList;
      if (!checkMyList()) {
        const newMylist = mylist.slice();
        newMylist.push(currentTrack);

        setMylist(newMylist);
      } else {
        if (!isAdd) {
          const newMylist = mylist.filter((t: any) => t !== currentTrack);
          setMylist(newMylist);
          if (isMyList) {
            onDismiss();
          }
        }
      }
      // console.log('currentTrack>>>>', currentTrack.id)
      updateMylistData(currentTrack.id, isAdd);
      setUpdateMylist(isAdd);

      present({
        duration: 2000,
        // buttons: [{ text: 'hide', handler: () => dismiss() }],
        message: `${isOnMyList ? "Removed from My List" : "Added to My List"}`,
        onDidDismiss: () => console.log("dismissed"),
        onWillDismiss: () => console.log("will dismiss"),
      });
      setIsOnMyList(isAdd);
    }
  };

  return (
    <IonModal
      cssClass={`info-modal ${platform.name}`}
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
    >
      {/* {console.log('mediaInfo>>>>', mediaInfo)} */}
      {/* {console.log("albums>>>", trackNo, albums)} */}
      <IonHeader className="ion-no-border">
        <div className="info-modal-content">
          <div className="pad-10">
            <div
              className="info-profile x-cursor-pointer"
              //----------- onClick={() => setShowPlaylistModal({ isOpen: true })}
              // dir={`${mediaInfo?.name_ar ? 'rtl' : 'auto'}`}
            >
              {/* {console.log("mediaInfo?>>", mediaInfo)} */}
              <div
                className="result-box"
                style={{ backgroundImage: `url(${mediaInfo?.image})` }}
              ></div>
              <div>
                {/* <div className='pad-10-lr size-20'>{mediaInfo?.name_ar ? mediaInfo?.name_ar : mediaInfo?.name}</div> */}
                <div className="pad-10-lr size-20">{mediaInfo?.name}</div>
                <div className="pad-10-lr color-666 size-12">
                  {mediaInfo?.artist}
                  {/* <span className='year'>2021</span> <span className='age'>16+</span> <span className='albums'>2 Parts</span> */}
                </div>
                <p className="pad-10-lr line-clamp-4 size-14 color-ccc">
                  {/* {mediaInfo?.description_ar
                    ? mediaInfo?.description_ar
                    : mediaInfo?.description} */}
                  {mediaInfo?.description}
                </p>
              </div>
            </div>

            {/* 
            <div className="grid grid-col-3">
              <div className="info-modal-actions pad-10-top">
                <div className="x-info-play">
                  <IonButton color="dark" onClick={() => playMedia(false)}>
                    <IonIcon className="cursor-pointer size-20" icon={play} />
                    &nbsp; Play
                  </IonButton>
                </div>
 
                <div className="at-center">
                 //-- hide Donwload button for web and iOS 
                  {albums && !["ios", "web"].includes(platform.name) && (
                    <>
                      <MediaDownload
                        track={albums[0]?.tracks[trackNo]}
                        // icon={downloadOutline}
                      />
                      
                    </>
                  )}
                </div>

                <div
                  className="at-center cursor-pointer"
                  onClick={() => playMedia(true)}
                >
                  <IonIcon
                    className="cursor-pointer size-20"
                    icon={playOutline}
                  />
                  <div className="size-12 color-666 at-flex-center-start">Preview</div>
                </div>
              </div>
            </div> */}

            <div
              className={`grid pad-10-top grid-col-${
                isWeb ? "2" : platform.name === "ios" ? "3" : "4"
                //---  isWeb ? "3" : "4"
              }`}
            >
              {/* {isWeb ? (
                <IonButton color="dark" onClick={() => playMedia(false)}>
                  <IonIcon className="cursor-pointer size-20" icon={play} />
                  &nbsp; Play
                </IonButton>
              ) : ( */}
              <div
                className="at-center cursor-pointer"
                onClick={() => playMedia(false)}
              >
                <IonIcon className="cursor-pointer size-30 at-flex-center-end" icon={playCircle} />
                <div className="size-12 color-666 at-flex-center-start">Play</div>
              </div>
              {/* )} */}

              {platform.name === "android" && (
                <div className="at-center cursor-pointer">
                  <MediaDownload track={albums[0]?.tracks[trackNo]} />
                </div>
              )}
              {!isWeb && (
                <div
                  className="at-center cursor-pointer"
                  onClick={updateMyList}
                >
                  <IonIcon
                    className="cursor-pointer size-30 at-flex-center-end"
                    icon={isOnMyList ? checkmarkOutline : addOutline}
                  />
                  <div className="size-12 color-666 at-flex-center-start">My List</div>
                </div>
              )}

              <div
                className="at-center cursor-pointer"
                onClick={() => playMedia(true)}
              >
                <IonIcon
                  className="cursor-pointer size-30 at-flex-center-end"
                  icon={playOutline}
                />
                <div className="size-12 color-666 at-flex-center-start">Preview</div>
              </div>
            </div>
          </div>
          {/*****
           <IonToolbar
            className="info-footer cursor-pointer pad-10"
            onClick={() => setShowPlaylistModal({ isOpen: true })}
          >
            <IonIcon
              slot="start"
              className="cursor-pointer size-20"
              icon={informationCircleOutline}
            />
            <IonTitle slot="start" className="size-12">
              {" "}
              Tracks & Info
            </IonTitle>
            <IonIcon
              slot="end"
              className="cursor-pointer size-20"
              icon={chevronForwardOutline}
            />
          </IonToolbar> */}
        </div>

        <IonIcon
          onClick={onClose}
          className="close cursor-pointer size-20 "
          icon={closeOutline}
        />
      </IonHeader>

      {isOpen && albums && (
        <>
          {/* <MediaPlayer
            tracks={tracks}
            // trackNo={trackNo}
            trackNo={refMediaPlayerTrackNo.current}
            isOpen={showMediaPlayer}
            onClose={() => setShowMediaPlayer(false)}
          /> */}

          {/* {console.log("playlist>>>",trackNo, playlist.tracks)} */}
          {showMediaPlayer && (
            <ModalAvtPlayer
              playlist={playlist.tracks}
              trackNo={trackNo}
              isOpen={showMediaPlayer}
              onClose={() => {
                setShowMediaPlayer(false);
              }}
            />
          )}

          {/* {console.log("albums>>>", albums)} */}
          {/* {console.log("trackNo>>>", trackNo)}
          {console.log("playlist>>>", playlist)} */}
          {/* {console.log("mediaInfo>>>", mediaInfo)} */}
          {/* {console.log('tracks>>>', trackNo, tracks)} */}
          {/*
         
         */}
          <UserAccessModal
            isOpen={modalUserAccess.isOpen}
            onClose={() => {
              setModalUserAccess({ isOpen: false });
            }}
          ></UserAccessModal>

          {/*****
           <PlaylistModal
            isOpen={showPlaylistModal.isOpen}
            onClose={() => setShowPlaylistModal({ isOpen: false })}
            // album={album}
            albums={albums}
            trackNo={trackNo}
          /> 
          
          */}
          {/* {console.log('mediaInfo.albumIndex |  trackno | albums==>>>>', mediaInfo.albumIndex, trackNo, albums)} */}
        </>
      )}
    </IonModal>
  );
};

export default ModalFromBottom;
