import { IonContent } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { LocalPlaylistContext } from "../contexts/LocalPlaylistContext";
import { MediaInfo } from "../definitions/MediaInterface";
import { initialAlbumTracks } from "../definitions/Settings";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";
import InfoModal from "./InfoModal";

const MyList: React.FC<any> = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [mediaInfo, setMediaInfo] = useState<MediaInfo>();
  const [trackNo, setTrackNo] = useState<any>(null);
  const { mylist, getMylist } = useContext(LocalPlaylistContext);
  const [albumTracks, setAlbumTracks] = useState<any>(initialAlbumTracks);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);

  useEffect(() => {
    getMylist();
  }, []);

  useEffect(() => {
    const newAlbumTracks = [];
    if (mylist && mylist.length > 0) {
      newAlbumTracks.push({
        id: 0,
        album_name: "Music Videos",
        album_info: "Music Videos",
        album_name_ar: "",
        album_info_ar: "",
        album_image: "",
        tracks: mylist,
      });
      // console.log('newAlbumTracks>>>>>>>>>>', newAlbumTracks)
      // console.log(">>>mylist>>>>>>>>>>", mylist);
      setTrackNo(0);
      setAlbumTracks(newAlbumTracks);
    }
  }, [mylist]);

  useEffect(() => {
    if (mylist && mylist.length > 0 && trackNo >= 0) {
      const currentTrack = albumTracks[0].tracks[trackNo];

      const artist = currentTrack?.artists
        ?.map((artist: any) => {
          return artist.name;
        })
        .join(", ");
      // console.log("artist>>>", artist);

      // console.log("albumTracks>>>", albumTracks[0].tracks[trackNo]);
      // console.log("currentTrack>>>", currentTrack);
      setMediaInfo({
        albumIndex: 0,
        //   albumIndex: refAlbumIndex.current,
        category: "ALBUM",
        name: currentTrack?.name,
        name_ar: currentTrack?.name_ar,
        //   description: currentTrack?.info,
        //   description_ar: currentTrack?.info_ar,
        description: currentTrack?.description,
        description_ar: currentTrack?.description_ar,
        image: currentTrack?.image?.replaceAll(".jpg", "-en-tall.jpg"),
        trackNo: 1,
        artist,
      });
    }
  }, [trackNo]);

  return (
    <IonContent>
      <br />
      <br />
      <br />
      <div className="pad-20 size-16">My List</div>
      {mylist && mylist?.length > 0 ? (
        <div className="page-search-content mylist">
          <div className="search-result">
            {mylist && mylist?.length > 0 ? (
              mylist.map((res: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="result-box"
                    style={{
                      backgroundImage: `url('${res.image.replaceAll(
                        ".jpg",
                        "-en-tall.jpg"
                      )}')`,
                    }}
                    onClick={() => {
                      // console.log('res>>>>', res, mylist)
                      // alert(index);
                      setShowInfoModal(true);

                      setTrackNo(index);
                      //////  setTrackNo(index);
                      ////// setShowMediaPlayer(true);
                    }}
                  >
                    {/* <div className="result-box-title at-center">
                      <div className="pad-5">
                        <div className="media-name"> {res.name}</div>
                        <div className="size-10 color-aaa">
                          {res?.artists[0]?.name}
                        </div>
                      </div>
                    </div> */}
                  </div>
                );
              })
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
                No music video added to list yet.{" "}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="pad-20-left color-aaa">
          No music video added to list yet.{" "}
        </div>
      )}

      {showMediaPlayer && (
        <ModalAvtPlayer
          playlist={albumTracks[0].tracks}
          trackNo={trackNo}
          isOpen={showMediaPlayer}
          onClose={() => setShowMediaPlayer(false)}
        />
      )}
      {/* {console.log("mylist>>>",trackNo, mylist)} */}
      {/* {console.log("[albumTracks[0]]>>>>>>>>>>>>>>",trackNo, albumTracks[0])} */}
      {/*  {albumTracks && (
        <InfoModal
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
         albums={[albumTracks[0]]}
          trackNo={trackNo}
          mediaInfo={mediaInfo}
        />
      )} */}
      {showInfoModal && albumTracks && (
        // <InfoModal
        //   isOpen={showInfoModal}
        //   onClose={() => setShowInfoModal(false)}
        //   albums={[mylist]}
        //   trackNo={trackNo}
        //   mediaInfo={mediaInfo}
        // />
        <InfoModal
          isOpen={showInfoModal}
          onClose={() => {
            setShowInfoModal(false);
          }}
          // albums={[albumInfo[refAlbumIndex.current]]}
          // albums={[albumTracks[trackNo]]}
          // albums={[albumTracks[0]]}
          albums={albumTracks[0]}
          trackNo={trackNo}
          mediaInfo={mediaInfo}
          // platformName={platform.name}
          playlist={albumTracks[0]}
          isMyList={true}
        />
      )}
    </IonContent>
  );
};

export default MyList;
