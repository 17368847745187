import { User, Doc } from "../definitions/MediaInterface";

export const USER_LOADING = "USER_LOADING";
export const USER_FAIL = "USER_FAIL";
export const USER_SUCCESS = "USER_SUCCESS";
export const FETCH_USERS = "FETCH_USERS";
export const DOC_LOADING = "DOC_LOADING";
export const DOC_FAIL = "DOC_FAIL";
export const DOC_SUCCESS = "DOC_SUCCESS";
export const FETCH_DOCS = "FETCH_DOCS";
export const DOC_STATUS_PROCESSING = "DOC_STATUS_PROCESSING";
export const DOC_STATUS_FAIL = "DOC_STATUS_FAIL";
export const DOC_STATUS_SUCCESS = "DOC_STATUS_SUCCESS";
export const FETCH_USER_INFO= 'FETCH_SETUP_INFO';

export interface UserLoading {
  type: typeof USER_LOADING;
}

export interface UserFail {
  type: typeof USER_FAIL;
}

export type UserType = {
  items: User[];
};

export interface UserSuccess {
  type: typeof USER_SUCCESS;
  payload: UserType[];
}

export interface DocLoading {
  type: typeof DOC_LOADING;
}

export interface DocFail {
  type: typeof DOC_FAIL;
}

export type DocType = {
  items: Doc[];
};

export interface DocSuccess {
  type: typeof DOC_SUCCESS;
  payload: DocType[];
}



export interface DocStatusProcessing {
  type: typeof DOC_STATUS_PROCESSING;
}

export interface DocStatusFail {
  type: typeof DOC_STATUS_FAIL;
}
 
export interface DocStatusSuccess {
  type: typeof DOC_STATUS_SUCCESS;
}

export type UserDispatchTypes = UserLoading | UserFail | UserSuccess;
export type DocDispatchTypes = DocLoading | DocFail | DocSuccess;
export type DocStatusDispatchTypes = DocStatusProcessing | DocStatusFail | DocStatusSuccess;
