import { createContext, useMemo, useState } from 'react'
export const UserContext = createContext<any>(null);

export const UserProfider = ({ children }: { children: any }) => {
  const [user, setUser] = useState(null);
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  )
}

