import { FETCH_USERS, USER_SUCCESS,  } from '../actions/userActionTypes'
 
const initialState = {
    items: [],
}

const UsersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                items: action.payload
            }
        case USER_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export default UsersReducer;