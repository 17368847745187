import { useRef, useEffect, useContext } from "react";

import { PlatformContext } from "../contexts/PlatformContext";
import { NavigationBar } from "@ionic-native/navigation-bar";
import { AndroidFullScreen } from "@ionic-native/android-full-screen";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

import videojs from "video.js";
import "videojs-plus";
// import "videojs-plus/dist/videojs-plus.css";
import "video.js/dist/video-js.css";
// import "../styles/at-player.css";
// import "../styles/movie.css";

const defaultPlayerOptions = {
  autoplay: false,
  muted: false,
  aspectRatio: "16:9",
  mobileView: false,
  preload: true,
};

const controlBar = {
  children: [
    "playToggle",
    "currentTimeDisplay",
    "timeDivider",
    "durationDisplay",
    "remainingTimeDisplay",
    "audioTrackButton",
    "volumeControl",
    "fullscreenToggle",
    "progressControl",
  ],
};
export function AvtPlayer({
  children,
  playerOptions,
  onPlayerInit,
  onPlayerDispose,
  isVisible = true,
}) {
  const containerRef = useRef(null);
  const { platform } = useContext(PlatformContext);

  useEffect(() => {
    if (containerRef.current) {
      const videoEl = containerRef.current.querySelector("video");
      const player = videojs(videoEl, {
        ...defaultPlayerOptions,
        ...playerOptions,
        controlBar,
      });

      // used to move children component into player's elment
      // your may not thest script
      const playerEl = player.el();
      const flag = player.getChild("PlayToggleLayer").el();
      for (const child of containerRef.current.children) {
        if (child !== playerEl) {
          playerEl.insertBefore(child, flag);
        }
      }
      // ----
      onPlayerInit && onPlayerInit(player);

      // for debug purpose
      window.player = player;

      //--- for mobile devices
      player.controlBar.addClass(platform.name);
      //-- lock orientation to landscape
      if (platform.name !== "web") {
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);

        console.log("onReady...");
      
        if (platform.name === "android") {
          const autoHideNavigationBar = true;
          NavigationBar.setUp(autoHideNavigationBar);
          
          //------- https://github.com/mesmotronic/cordova-plugin-fullscreen
          AndroidFullScreen.isImmersiveModeSupported()
            .then(() => {
              //--- remove comment to enable andriod fullscreen mode
              AndroidFullScreen.immersiveMode();
            })
            .catch((err) => console.log("err>>>", err));
        }

        if (player.supportsFullScreen()) {
          player.requestFullscreen();
        }

      }

      return () => {
        onPlayerDispose && onPlayerDispose(null);
        player.dispose();

        // if (platform.name === "android") {
        //   NavigationBar.setUp(false);
        //   // NavigationBar.show();
        //   AndroidFullScreen.isImmersiveModeSupported()
        //     .then(() => {
        //       //--- remove comment to enable andriod fullscreen mode
        //       AndroidFullScreen.showSystemUI();
        //     })
        //     .catch((err) => console.log("err>>>", err));
        // }
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
        ScreenOrientation.unlock();
      };
    }
    // eslint-disable-next-line
  }, [onPlayerInit, onPlayerDispose, playerOptions]);

  return (
    <>
      <div className="at-player" ref={containerRef} hidden={!isVisible}>
        <video preload={platform.name === "web"? "true":"metadata"} className="video-js" />
        {/* <video preload="true" className="video-js" autoplay muted /> */}
        {/* <video className="video-js" autoplay muted /> */}
        {children}
      </div>
    </>
  );
}
