import { IonButton, IonIcon, IonLabel, useIonToast } from "@ionic/react";
import {
  addOutline,
  checkmarkOutline,
  informationCircleOutline,
  play,
} from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { PlatformContext } from "../contexts/PlatformContext";
import { MediaInfo } from "../definitions/MediaInterface";

import "../styles/takeone-mobile.css";
import "../styles/takeone-web.css";

// import MediaPlayer from "../utilities/MediaPlayer";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";

import InfoModal from "./InfoModal";
import PlayPreview from "./PlayPreview";
import { LocalPlaylistContext } from "../contexts/LocalPlaylistContext";
import UserAccessModal from "./UserAccessModal";
const Hero3: React.FC<any> = ({ albumTracks, trackNo }) => {
  const [modalUserAccess, setModalUserAccess] = useState({ isOpen: false });
  const [showMediaPreview, setShowMediaPreview] = useState<boolean>(true);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const [isOnMyList, setIsOnMyList] = useState<boolean>(false);
  const [tracks, setTracks] = useState<any>();
  const [currentTrack, setCurrentTrack] = useState<any>();
  const { platform } = useContext(PlatformContext);
  const { mylist, setMylist, setUpdateMylist, updateMylistData } =
    useContext(LocalPlaylistContext);
  // const [present, dismiss] = useIonToast();
  const [present] = useIonToast();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [mediaInfo, setMediaInfo] = useState<MediaInfo>();
  const refAlbumIndex = useRef(0);

  const refTrackNo = useRef<number>(trackNo);
  // const refTrackNo = useRef<number>(0);

  useEffect(() => {
    checkMyList();
  }, []);

  useEffect(() => {
    if (albumTracks) {
      // setCurrentTrack(albumTracks[0].tracks[trackNo]);
      setCurrentTrack(albumTracks[0].tracks[refTrackNo.current]);
      setTracks(albumTracks[0].tracks);
    }
  }, [albumTracks]);

  const playMedia = () => {
    setShowMediaPreview(false);
    setShowMediaPlayer(true);
  };

  const clickHandler = (e: any) => {
    if (
      !(
        e.target.tagName.toUpperCase().includes("ION-") ||
        e.target.className.toUpperCase().includes("ION-")
      )
    ) {
      setShowInfoModal(true);
    }
  };

  useEffect(() => {
    if (currentTrack) {
      // console.log("currentTrack>>>", currentTrack);
      const album_active = albumTracks[refAlbumIndex.current];
      const artist = currentTrack?.artists
        ?.map((artist: any) => {
          return artist.name;
        })
        .join(", ");

      const { name, name_ar, description, description_ar, image } =
        currentTrack;

      setMediaInfo({
        albumIndex: refAlbumIndex.current,
        category: "ALBUM",
        // name: currentTrack?.name,
        // name_ar: currentTrack?.name_ar,
        // description: currentTrack?.info,
        // description_ar: currentTrack?.info_ar,
        // image: currentTrack?.image,
        name,
        name_ar,
        description,
        description_ar,
        image,
        trackNo: 1,
        artist,
      });
    }
  }, [currentTrack]);

  const checkMyList = () => {
    if (platform.name !== "web" && albumTracks && mylist) {
      const trackId = albumTracks[0]?.tracks[trackNo]?.id;
      const index = mylist?.findIndex((list: any) => list.id === trackId);
      setIsOnMyList(index >= 0);
      return index >= 0;
    }
    return false;
  };

  const updateMyList = () => {
    // console.log("currentTrack>>>>", currentTrack);
    const user_id = localStorage.getItem("uid");
    if (!user_id) {
      setModalUserAccess({ isOpen: true });
      return false;
    }

    const isAdd = !isOnMyList;
    if (!checkMyList()) {
      const newMylist = mylist.slice();
      newMylist.push(currentTrack);

      setMylist(newMylist);
    } else {
      if (!isAdd) {
        const newMylist = mylist.filter((t: any) => t !== currentTrack);
        setMylist(newMylist);
      }
    }

    updateMylistData(currentTrack.id, isAdd);
    setUpdateMylist(isAdd);

    present({
      duration: 2000,
      // buttons: [{ text: 'hide', handler: () => dismiss() }],
      message: `${isOnMyList ? "Removed from My List" : "Added to My List"}`,
      onDidDismiss: () => console.log("dismissed"),
      onWillDismiss: () => console.log("will dismiss"),
    });
    setIsOnMyList(isAdd);
  };

  return (
    <>
      {/* {console.log("mylist>>>", mylist)}
      {console.log("albumTracks>>>", albumTracks)}
       */}

      <div className="main-row-0" onClick={clickHandler}>
        <div className="main-row-0-content">
          {showMediaPreview && platform.name === "web" ? (
            <PlayPreview
              albumTracks={albumTracks}
              trackNo={trackNo}
              isOpen={showMediaPreview}
              onClose={() => {
                setShowMediaPreview(false);
              }}
            />
          ) : (
            <img
              className="hero-image"
              // src="https://api.takeone.studio/media/haitham_yousif--mayhmni/haitham_yousif--mayhmni.jpg"
              src={`${currentTrack?.image}`}
              alt="--"
            />
          )}

          <div className="hero-info ">
            <div className=" title size-46 at-bold">{currentTrack?.name}</div>
            <div className=" artist size-18 xcolor-aaa">
              {currentTrack?.artists[0]?.name}
            </div>
          </div>

          <div className="hero-menu pad-10-top">
            <div className="hero-menu-item at-bold">
              {!["web"].includes(platform.name) && (
                <IonButton
                  onClick={updateMyList}
                  className="button-transparent"
                  style={{ height: "50px" }}
                >
                  <div>
                    <IonIcon
                      icon={isOnMyList ? checkmarkOutline : addOutline}
                      className="at-bold size-26 at-flex-center-end"
                      style={{ width: "40px" }}
                    />
                    <div
                      style={{ textTransform: "none", width: "46px" }}
                      className="ion-label size-12 at-flex-center-start pad-5-top"
                    >
                      My List
                    </div>
                  </div>
                </IonButton>
              )}

              <IonButton
                color="dark"
                onClick={playMedia}
                className="xsize-22"
                style={{ margin: "0 5vw", height: "42px" }}
              >
                <IonIcon icon={play} className="at-bold xsize-26" />
                <IonLabel className="at-bold xsize-16">Play</IonLabel>
              </IonButton>

              <IonButton
                onClick={() => setShowInfoModal(true)}
                className="button-transparent"
                style={{ height: "50px" }}
              >
                <div>
                  <IonIcon
                    icon={informationCircleOutline}
                    className="size-24 at-flex-center-end"
                    style={{ width: "36px" }}
                  />

                  <div
                    style={{ textTransform: "none", width: "30px" }}
                    className="ion-label size-12 at-flex-center-start pad-5-top"
                  >
                    Info
                  </div>
                </div>
              </IonButton>
            </div>
          </div>
        </div>
        <div className="gradient-shadow-bottom"></div>
      </div>
      {showMediaPlayer && (
        <ModalAvtPlayer
          playlist={tracks}
          trackNo={refTrackNo.current}
          isOpen={showMediaPlayer}
          onClose={() => {
            setShowMediaPlayer(false);
          }}
        />
      )}
      {albumTracks && showInfoModal && (
        //   <InfoModal
        //   isOpen={showInfoModal}
        //   onClose={() => setShowInfoModal(false)}
        //   albums={albumTracks}
        //   trackNo={0}
        //   mediaInfo={mediaInfo}
        // />

        <InfoModal
          isOpen={showInfoModal}
          onClose={() => {
            setShowInfoModal(false);
            checkMyList();
          }}
          // albums={[albumInfo[refAlbumIndex.current]]}
          albums={[albumTracks[refAlbumIndex.current]]}
          trackNo={trackNo}
          mediaInfo={mediaInfo}
          // platformName={platform.name}
          playlist={albumTracks[0]}
        />
      )}
      <UserAccessModal
        isOpen={modalUserAccess.isOpen}
        onClose={() => {
          setModalUserAccess({ isOpen: false });
        }}
      ></UserAccessModal>

      {/* {console.log("mylist >>>", mylist)} */}
      {/* {console.log("platform.name >>>", platform.name)} */}

      {/* {console.log("albumTracks>>>", albumTracks[0])} */}
      {/* {console.log('mediaInfo>>>', mediaInfo)} */}
      {/* {console.log("currentTrack>>>", currentTrack)} */}
    </>
  );
};

export default Hero3;
