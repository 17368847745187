import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
// import { Capacitor, } from '@capacitor/core';
import { IonHeader, IonModal, useIonModal } from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { PlatformContext } from "../contexts/PlatformContext";
// import { isArabic } from "../utilities";

import ReactDOM from "react-dom";
import videojs from "video.js";
import "videojs-playlist";
// import "videojs-landscape-fullscreen";

import "video.js/dist/video-js.css";
import "../styles/movie.css";
import "../styles/equalizer.css";

import { NavigationBar } from "@ionic-native/navigation-bar";

import { AndroidFullScreen } from "@ionic-native/android-full-screen";

// import {
//   Plugins,
//   // StatusBarStyle,
// } from "@capacitor/core";
// const { StatusBar } = Plugins;

let isAudioGlobal = false;
let currentPlayer = null;
// eslint-disable-next-line
let myPlayer = null;

const initialOptions = {
  autoplay: true,
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};
const controlBar = {
  children: [
    "playToggle",
    "currentTimeDisplay",
    "timeDivider",
    "durationDisplay",
    "remainingTimeDisplay",
    "audioTrackButton",
    "volumeControl",
    "fullscreenToggle",
    "progressControl",
  ],
};
const videoJsOptions = {
  autoplay: true,
  controls: true,
  sources: [
    {
      src: "https://vjs.zencdn.net/v/oceans.mp4",
      type: "video/mp4",
    },
  ],
  controlBar: {
    volumePanel: {
      inline: false,
    },
    // fullscreenToggle:true,
  },
};

const MediaPlayer = ({
  isOpen,
  onClose,
  trackNo,
  tracks,
  showPlayerLoader = true,
}) => {
  const { platform } = useContext(PlatformContext);
  const myVideoNode = useRef();
  // const videoNode = useRef();
  // const player = useRef();

  const myTrackNo = useRef(trackNo);
  const currentTime = useRef(0);
  const [isAudio, setIsAudio] = useState(isAudioGlobal);
  const [isLoaded, setIsLoaded] = useState(false);

  const refIsOpen = useRef(false);

  useEffect(() => {
    if (tracks) {
      // console.log("tracks============>>>>>>>", trackNo, tracks[trackNo]);
    }

    if (tracks?.length <= 0) {
      myTrackNo.current = 0;
    } else {
      myTrackNo.current = trackNo;
    }
    // console.log('>>>>>>>running...')
  }, [tracks]);

  const onDismiss = () => {
    // setState({ showPlaylist: false, isAudioGlobal: false });
    // setIsAudio(false);
    // StatusBar.setOverlaysWebView({ overlay: false });
    // StatusBar.show();
    if (platform.name === "android") {
      NavigationBar.setUp(false);
      // NavigationBar.show();
      AndroidFullScreen.isImmersiveModeSupported()
        .then(() => {
          //--- remove comment to enable andriod fullscreen mode
          AndroidFullScreen.showSystemUI();
        })
        .catch((err) => console.log("err>>>", err));
    }
    ScreenOrientation.unlock();
    onClose();
  };

  // const toggleButtons =
  //   useCallback(
  //     (playr) => {
  //       console.log('44444444')

  //       const btnPrevious = playr.controlBar.el_.querySelector('.vjs-button.previous')
  //       const btnNext = playr.controlBar.el_.querySelector('.vjs-button.next')
  //       btnPrevious.disabled = playr.playlist.currentItem() === 0;
  //       btnNext.disabled = playr.playlist.currentItem() >= playr.playlist.lastIndex();
  //     },
  //     [],
  //   )
  const toggleButtons = useCallback(() => {
    // console.log( trackNo, '>>>>', myTrackNo.current, currentPlayer.playlist.currentItem(),currentPlayer.playlist.lastIndex(), tracks);

    if (currentPlayer) {
      const btnPrevious = currentPlayer.controlBar.el_.querySelector(
        ".vjs-button.previous"
      );
      const btnNext =
        currentPlayer.controlBar.el_.querySelector(".vjs-button.next");
      if (btnPrevious) {
        btnPrevious.disabled = currentPlayer.playlist.currentItem() === 0;
      }
      if (btnNext) {
        btnNext.disabled =
          currentPlayer.playlist.currentItem() >=
          currentPlayer.playlist.lastIndex();
      }
    }
  }, []);

  const addMediaTitle = useCallback((playr, title) => {
    // console.log('5555555')
    var elMediaTitle = playr.el_.querySelector(
      ".video-js .vjs-button.video-title"
    );
    const mediaTitle = playr.el_.querySelector(
      ".video-js .vjs-button.video-title .vjs-control-text"
    );
    mediaTitle.classList.add("media-title");
    mediaTitle.innerHTML = title;

    while (elMediaTitle.firstChild) {
      elMediaTitle.removeChild(elMediaTitle.lastChild);
    }

    elMediaTitle.appendChild(playr.controlBar.currentTimeDisplay.el_);
    elMediaTitle.appendChild(playr.controlBar.timeDivider.el_);
    elMediaTitle.appendChild(playr.controlBar.durationDisplay.el_);
    elMediaTitle.appendChild(mediaTitle);
  }, []);

  const handleTrackSelected = useCallback((num) => {
    // console.log('66666666>>.', num, currentPlayer);
    currentPlayer.playlist.currentItem(num);
    handleDismissLibrary();
  }, []);

  const handleDismissLibrary = useCallback(() => {
    dismissLibrary();
  }, []);

  const modalLibrary = useCallback(
    ({ onDismiss, onTrackSelected, tracks }) => (
      <div className="modal-library">
        <div>
          <div className="title-bar">
            <div className="title">Your Playlist</div>
            <div>
              <button className="close_modal" onClick={() => onDismiss()}>
                ✕
              </button>
            </div>
          </div>

          <div className="items" data-vjs-player="true">
            {tracks &&
              tracks.map((t, i) => {
                return (
                  <div
                    // dir={`${isArabic(t.name)?'rtl':'auto'}`}
                    className={`item ${
                      myTrackNo.current === i ? "playlist_active" : ""
                    }`}
                    key={i}
                    onClick={() => onTrackSelected(i)}
                  >
                    <div>
                      <div
                        className="album_art"
                        style={{ backgroundImage: `url('${t.poster}')` }}
                      ></div>
                    </div>
                    <div>
                      <div className="name">{t.name}</div>
                      {/* <div className='artist'>{t.artist}</div> */}
                      <div className="artist">{t.artists[0]?.name}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    ),
    []
  );

  const [presentLibrary, dismissLibrary] = useIonModal(modalLibrary, {
    onTrackSelected: handleTrackSelected,
    onDismiss: handleDismissLibrary,
    tracks: tracks,
  });

  const addExtraButtons = useCallback(
    (playr) => {
      // console.log('777777777')
      // console.log('addExtraButtons...')

      const Button = videojs.getComponent("Button");
      const CloseButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("close_modal");
        },
        handleClick: function () {
          onDismiss();
        },
      });

      const PrevButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("previous");
          this.controlText("Previous");
        },
        handleClick: function () {
          try {
            const p = playr.playlist.previous();
            setTitle(p.name);
            setIsLoaded(false);
            playr.poster("./assets/img/takeone-logo-square.jpg");
          } catch (error) {
            console.log("error>>>", error);
          }
        },
      });

      const NextButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("next");
          this.controlText("Next");
        },
        handleClick: function () {
          try {
            const p = playr.playlist.next();
            setTitle(p.name);
            setIsLoaded(false);
            playr.poster("./assets/img/takeone-logo-square.jpg");
          } catch (error) {}
        },
      });

      const VideoTitle = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("video-title");
        },
      });

      const LibraryBox = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("library-box");
        },
        handleClick: function () {
          try {
            presentLibrary({ cssClass: "modal-library" });
          } catch (error) {}
        },
      });

      const MediaToggle = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.controlText("AUDIO");
          ////////////////////////this.addClass('media_toggle');  //- disabled on 2021-10-24
          this.addClass("with-icon");
          // this.addClass('is-audio');
          // alert('isAudioGlobalTest>>>>'+isAudioGlobalTest);
        },
        handleClick: function () {
          isAudioGlobal = !isAudioGlobal;
          if (isAudioGlobal) {
            this.addClass("is-audio");
          } else {
            this.el_.classList.remove("is-audio");
          }
          setIsAudio(isAudioGlobal);
          changeMedia();
        },
      });

      videojs.registerComponent("PrevButton", PrevButton);
      videojs.registerComponent("NextButton", NextButton);
      videojs.registerComponent("VideoTitle", VideoTitle);
      videojs.registerComponent("LibraryBox", LibraryBox);
      videojs.registerComponent("CloseButton", CloseButton);
      videojs.registerComponent("MediaToggle", MediaToggle);

      playr.controlBar.addChild("PrevButton", {}, 1);
      playr.controlBar.addChild("NextButton", {}, 2);
      playr.controlBar.addChild("VideoTitle", {}, 3);
      playr.controlBar.addChild("LibraryBox", {}, 10);
      playr.controlBar.addChild("CloseButton", {}, 99);
      playr.controlBar.addChild("MediaToggle", {}, 100);

      if (tracks.length <= 0) {
        myTrackNo.current = 0;
        // addMediaTitle(playr, tracks[0].name);
      } else {
        myTrackNo.current = trackNo;
        // addMediaTitle(playr, tracks[trackNo].name);
      }

      // console.log('tracks[trackNo].name >>>', myTrackNo.current, trackNo, tracks, tracks[trackNo].name, tracks[trackNo].sources[0].src);

      addMediaTitle(
        playr,
        tracks[myTrackNo.current]?.name +
          " - " +
          tracks[myTrackNo.current]?.artists[0]?.name
      );
    },
    [tracks]
  );

  const setTitle = useCallback((title) => {
    // console.log('8888')
    /** Set Media Title */
    var mountElement = document.querySelector(
      ".video-js .video-title .media-title"
    );
    if (mountElement) {
      var boldElement = React.createElement("span", null, title);
      ReactDOM.render(boldElement, mountElement);
    }
  }, []);

  const changeMedia = useCallback(() => {
    if (currentPlayer) {
      currentTime.current = currentPlayer.currentTime();

      tracks.forEach((t, i) => {
        let track = t.sources[0].src;
        if (isAudioGlobal) {
          track = track
            .replace("master.m3u8", "audio.m3u8")
            .replace("/video", "/audio");
        } else {
          track = track
            .replace("audio.m3u8", "master.m3u8")
            .replace("/audio", "/video");
        }
        t.sources[0].src = track;
      });

      currentPlayer.playlist(tracks);
      currentPlayer.playlist.currentItem(myTrackNo.current);
      currentPlayer.currentTime(currentTime.current);
    }
  }, [tracks]);

  useEffect(() => {
    // console.log('11111111111')
    refIsOpen.current = isOpen;
    if (isOpen) {
      // alert('isOPen')
      // console.log('tracks >>>', tracks)
      // if (myPlayer === null) {
      myPlayer = videojs(myVideoNode.current, {
        ...initialOptions,
        ...videoJsOptions,
        controlBar,
      }).ready(function () {
        this.controlBar.addClass(platform.name);
        // this.controlBar.addClass('ios'); //-- temporary

        //-- lock orientation to landscape
        if (platform.name !== "web") {
          ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);

          console.log("onReady...");
          // StatusBar.hide();
          // StatusBar.setOverlaysWebView({ overlay: true });
          // let autoHide: boolean = true;
          // NavigationBar.setUp({ autoHide: true });

          if (platform.name === "android") {
            const autoHideNavigationBar = true;
            NavigationBar.setUp(autoHideNavigationBar);
            // NavigationBar.hide();
            // NavigationBar.backgroundColorByHexString("darkGray");
            // NavigationBar.backgroundColorByHexString("#000000", true);

            //------- https://github.com/mesmotronic/cordova-plugin-fullscreen
            AndroidFullScreen.isImmersiveModeSupported()
              .then(() => {
                //--- remove comment to enable andriod fullscreen mode
                AndroidFullScreen.immersiveMode();
              })
              .catch((err) => console.log("err>>>", err));
          }

          if (this.supportsFullScreen()) {
            this.requestFullscreen();
          }

          //// if (!this.isFullscreen()) {
          ////   this.requestFullscreen();
          //// }

          // this.controlBar.removeChild('fullscreenToggle');
        }

        currentPlayer = this;
        if (tracks.length <= 0) {
          myTrackNo.current = 0;
        } else {
          myTrackNo.current = trackNo;
        }

        this.playlist(tracks);
        this.playlist.autoadvance(0);
        this.playlist.currentItem(myTrackNo.current);
        this.playlist.repeat(true);
        this.play();

        this.poster("./assets/img/takeone-logo-square.jpg");

        if (
          this.el_.querySelector(".vjs-control.vjs-button.previous") === null
        ) {
          addExtraButtons(this);
        }

        toggleButtons();
        this.on("playlistitem", function () {
          toggleButtons();
          myTrackNo.current = this.playlist.currentItem();
          setTitle(
            tracks[myTrackNo.current]?.name +
              " - " +
              tracks[myTrackNo.current]?.artists[0]?.name
          );
        });

        this.on("userinactive", function () {
          if (platform.name !== "web") {
            // console.log("userinactive...", isOpen, refIsOpen.current)
            // StatusBar.hide();
            // if (refIsOpen.current) {
            //   StatusBar.hide();
            //   NavigationBar.hide();
            // } else {
            //   StatusBar.setOverlaysWebView({ overlay: false });
            //   StatusBar.show();
            // }
          }
        });
        this.on("ended", function () {
          setIsLoaded(false);
          if (
            isOpen &&
            tracks[myTrackNo.current].sources[0].src.includes("-preview.")
          ) {
            onDismiss();
          }
        });

        // this.on("playing", function () {
        //   setIsLoaded(true);
        //   alert("playing>>>>");
        // });
        // this.onloadeddata = (event) => {
        //   setIsLoaded(true);
        //   alert(
        //     "Yay! The readyState just increased to  " +
        //       "HAVE_CURRENT_DATA or greater for the first time."
        //   );
        // };

        this.on("loadeddata", function () {
          setIsLoaded(true);
          // alert("loadeddata>>>>");
        });

        // this.on("loadedmetadata", function () {
        //   setIsLoaded(true);
        //   alert("loadedmetadata >>>>");
        // });

        /*
        setUserActive(true);
        this.on("userinactive", function () {
          setUserActive(false);
          setState({ showPlaylist: false });
          if (platform !== 'web') {
            StatusBar.hide();
          }
        });
        this.on("useractive", function () {
          setUserActive(true);
        });
      */
      });
      // }
    } else {
      // myPlayer = null;
      // StatusBar.setOverlaysWebView({ overlay: false });
      // StatusBar.show();
    }

    // console.log('myPlayer >>>', myPlayer)
    return () => {
      // StatusBar.setOverlaysWebView({ overlay: false });
      // StatusBar.show();
      // if (player.current) {
      //   player.current.dispose();
      // }
    };
  }, [isOpen]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} cssClass="modal-player">
      {/* {console.log('rendered..')} */}
      {/* {console.log('tracks=======>>>',myTrackNo.current, trackNo, tracks)} */}
      <IonHeader>
        <video ref={myVideoNode} className="video-js" preload="auto" />

        {!isLoaded && showPlayerLoader && (
          <div className="player-loader">
            <div className="at-container at-center-screen">
              {/* <img
              className='xat-center xvertical-center'
                src="./assets/img/takeone-logo.png"
                alt=""
                height="140"
              /> */}

              <div
                data-aos="fade-down"
                data-aos-duration="3000"
                className="logo aos-init aos-animate"
              >
                <img
                  src="https://takeone.studio/img/takeone-finalized-mini.png"
                  alt=""
                />

                <div className="logo_wrapper">
                  <div className="equalizer">
                    <div className="lines one"></div>
                    <div className="lines two"></div>
                    <div className="lines three"></div>
                    <div className="lines four"></div>
                    <div className="lines five"></div>
                    <div className="lines six"></div>
                    <div className="lines seven"></div>
                    <div className="lines eight"></div>
                    <div className="lines nine"></div>
                    <div className="lines ten"></div>
                    <div className="lines eleven"></div>
                    <div className="lines twelve"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonHeader>
    </IonModal>
  );
};

export default MediaPlayer;
