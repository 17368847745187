import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
// import { useParams } from "react-router";
import { PlatformContext } from "../contexts/PlatformContext";
import MediaUploader from "../utilities/MediaUploader";
import PdfViewer from "../utilities/PdfViewer";
import ReactFileViewer from "../utilities/ReactFileViewer";
import "../styles/file-uploader.css";

const DashboardUser: React.FC<any> = () => {
  //   let { username } = useParams<any>();
  const { platform } = useContext(PlatformContext);
  const [userFiles, setUserFiles] = useState<any>([]);
  const [showPage, setShowPage] = useState<boolean>(false);
  const [modalFileViewer, setModalFileViewer] = useState({ isOpen: false });
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const refFile = useRef<string>(
    // "https://api.takeone.studio/user/adonis@tividad.com/avtividad-3hX3Ewgn9NB4HWBk9LACyU.pdf"
    "https://api.takeone.tividad.com/user/adonis@tividad.com/avtividad-3hX3Ewgn9NB4HWBk9LACyU.pdf"
  );

  const getFileExtension: any = (filename: string) => {
    const re = /(?:\.([^.]+))?$/;
    const test = re.exec(filename);
    if (test) {
      return test[1];
    } else {
      return "";
    }
  };

  useEffect(() => {
    const fetchUserFiles = async () => {
      //   const doc_status = refDocStatus.current;
      const { data } = await axios.post(
        // "http://localhost:5000/api/v1/user/fetch-userfiles",
        // "https://api.takeone.studio/api/v1/user/fetch-users",
        "https://api.takeone.tividad.com/api/v1/user/fetch-users",
        {
          email: platform.email,
        }
      );
      setUserFiles(data.results);
      // console.log("data.results>>>", data.results);
      //   setUsers(data.results);
    };

    // console.log("isUpdateData>>>", isUpdateData);
    const timerId = setTimeout(() => {
      fetchUserFiles();
    }, 200);
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line
  }, [isUpdateData]);

  const onClickFileIcon = (filename: any, index: number) => {
    // refFile.current = `https://api.takeone.studio/user/${platform?.email}/${filename}`;
    refFile.current = `https://api.takeone.tividad.com/user/${platform?.email}/${filename}`;
    // console.log("refFile.current>>>>>", refFile.current);
    setModalFileViewer({ isOpen: true });
  };
  const uploadVideo = () => {
    setShowPage(true);
  };

  const getTitle = () => {
    return (
      <div>
        <div
          className="large_title"
          style={{ color: "#fff", fontSize: "15px", paddingBottom: "20px" }}
        >
          <div style={{ color: "#fff", fontSize: "18px", textAlign: "center" }}>
            {" Only .mp4 files are accepted. "}
          </div>
        </div>
      </div>
    );
  };
  const addUserFiles = (arrFiles: any) => {
    // axiosInstance.post( "user/add-userfiles", JSON.stringify(arrFiles))
    axios({
      method: "post",
      // url: "http://localhost:5000/api/v1/user/add-userfiles",
      // url: "https://api.takeone.studio/api/v1/user/add-userfiles",
      url: "https://api.takeone.tividad.com/api/v1/user/add-userfiles",
      data: JSON.stringify(arrFiles),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res: any) => {})
      .catch((res: any) => {
        // setErrorMessage(response.data.msg);
      });
  };
  const onUploadSuccess = (files: any) => {
    console.log("files>>>>>>>", files);
    setShowPage(false);
    const userFiles = files.map((filename: any) => {
      return { email: platform.email, filename, file_type: "media" };
    });
    addUserFiles(userFiles);
    setIsUpdateData(!isUpdateData);
  };
  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="grid-list">
        <div className="pad-20-left">

        <IonButton color="secondary" onClick={uploadVideo} style={{ maxWidth: "200px" }}>
          {" "}
          Upload video
        </IonButton>
        </div>
 
        <IonList>
          <IonListHeader>Uploaded</IonListHeader>
          {userFiles &&
            userFiles?.map((file: any, ndx: number) => {
              return (
                <IonItem>
                  <div>
                    <img
                        className='cursor-pointer'
                        src={`./assets/img/${getFileExtension(file.filename)}.png`}
                        alt="icon"
                        height="50"
                        onClick={() => onClickFileIcon(file.filename, ndx)}
                      />
                  </div>
                  <IonLabel>
                    <h3>{file.filename}</h3>
                    {file.status==='pending'
                      ?<IonLabel className="size-12 " color="danger" style={{fontSize:'13px'}}>{file.status.toUpperCase()} APPROVAL</IonLabel>
                      : <IonLabel className="size-12 " color="success"style={{fontSize:'13px'}}>{file.status.toUpperCase()}</IonLabel>
                    }
                  </IonLabel>
                </IonItem>
              );
            })}
        </IonList>
 
      </div>
      {refFile.current &&
        (refFile.current?.toLowerCase().includes(".pdf") ? (
          <PdfViewer
            isOpen={modalFileViewer.isOpen}
            onClose={() => {
              setModalFileViewer({ isOpen: false });
            }}
            file={refFile.current}
          />
        ) : (
          <ReactFileViewer
            isOpen={modalFileViewer.isOpen}
            onClose={() => {
              setModalFileViewer({ isOpen: false });
            }}
            file={refFile.current}
          />
        ))}
      {showPage && (
        <div className="file-uploader-window">
          <div className="parent-box">
            <div className="wrapper"></div>

            <div className="upload-box">
              <div className="upload-setting">
                {/* <MediaUploader folder={email} /> */}

                <MediaUploader
                  folder={`${platform.email}`}
                  // maxFiles={2}
                  minFiles={1}
                  acceptedFiles={["video/mp4"]}
                  showModalSuccess={onUploadSuccess}
                  title={getTitle()}
                  class_name="video"
                  buttonCaption={"SUBMIT"}
                />
                {/* <div>Only .mp4 files are accepted.</div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardUser;
