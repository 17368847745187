import React, { useContext, useRef, useState } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import { MediaInfo, Track } from "../definitions/MediaInterface";
import { PlatformContext } from "../contexts/PlatformContext";
// import { ImageTrack } from "../utilities/MediaImages";
// import InfoModal from "./InfoModal";
import MediaPlayer from "../utilities/MediaPlayer";
import PlayPreview from "./PlayPreview";
import InfoModal from "./InfoModal";

const Row1a: React.FC<any> = ({ heroAlbum, slideOpts, isTall }) => {
  const { platform } = useContext(PlatformContext);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [mediaInfo, setMediaInfo] = useState<MediaInfo>();
  const refTrackNo = useRef(0);

  const [showMediaPreview, setShowMediaPreview] = useState<boolean>(false);

  const showInfo = (index: number) => {
    refTrackNo.current = index;
    // console.log('heroAlbum>>>>', index, heroAlbum.tracks[index]);
    const track = heroAlbum.tracks[index];
    // console.log('heroAlbum>>>>', index, heroAlbum.tracks[index]);
    // console.log('track.album>>>>', track.album);
    // console.log("heroAlbum >>>>", heroAlbum.tracks);

    if (track) {
      const artist = track?.artists
        ?.map((artist: any) => {
          return artist.name;
        })
        .join(", ");
      setMediaInfo({
        // albumIndex: track.album,
        albumIndex: 0,
        category: "TRACK",
        name: track.name,
        description: track.description,
        name_ar: track.name_ar,
        description_ar: track.description_ar,
        // image: ImageAlbum(heroAlbum?.id, platform.name),
        // image: ImageTrack(track.id, platform.name),
        image: track.image,
        trackNo: index,
        // artist: track?.artists[0]?.name,
        artist,
      });
      setShowInfoModal(true);
      // setTracks(createTracks(album_active.tracks))
    }
  };

  // const playMedia = (index: number) => {
  //   refTrackNo.current = index;
  //   // alert("refTrackNo.current>>>" + refTrackNo.current);
  //   setShowMediaPlayer(true);
  // };

  return (
    <div>
      <br />
      <div className="size-18 at-bold">To get you started</div>

      {/* {console.log('heroAlbum>>>', heroAlbum)} */}
      {heroAlbum && (
        <IonSlides options={slideOpts} key={heroAlbum.id}>
          {heroAlbum?.tracks &&
            heroAlbum?.tracks?.map((track: Track, idx: number) => {
              return (
                <IonSlide
                  key={idx}
                  className={`${
                    platform.name === "web" ? "slide-wide" : "slide-default"
                  }  ${platform.name}`}
                  onClick={() => showInfo(idx)}
                  // onClick={() => playMedia(idx)}
                  style={{
                    backgroundImage: `url(${
                      track?.image
                        ? track?.image.replaceAll(
                            ".jpg",
                            `-en${isTall ? "-tall" : ""}.jpg`
                          )
                        : "./assets/img/takeone-logo-gray.png"
                    })`,
                    height: `${isTall ? "190px" : "170px"}`,
                    backgroundSize: "cover",
                    border: "1px solid #111",
                  }}
                  // dir={`${track.name_ar ? 'rtl' : 'auto'}`}
                  // onMouseOver={()=>alert('onMouseOver>>>')}
                >
                  {/* <div className="track-name">{track?.name} </div>
                  <div className="track-artists">
                    {track?.artists[0]?.name}{" "}
                  </div> */}
                </IonSlide>
              );
            })}
        </IonSlides>
      )}
      {showInfoModal && (
        <InfoModal
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
          albums={[heroAlbum]}
          trackNo={refTrackNo.current}
          mediaInfo={mediaInfo}
          playlist={heroAlbum}
        />
      )}
      {showMediaPlayer && (
        <MediaPlayer
          tracks={heroAlbum.tracks}
          trackNo={refTrackNo.current}
          isOpen={showMediaPlayer}
          onClose={() => {
            setShowMediaPlayer(false);
          }}
        />
      )}
      {showMediaPreview && (
        <PlayPreview
          albumTracks={heroAlbum.tracks}
          trackNo={refTrackNo.current}
          isOpen={showMediaPreview}
          onClose={() => {
            setShowMediaPreview(false);
          }}
        />
      )}
    </div>
  );
};

export default Row1a;
