import { useEffect, useMemo, useState } from "react";
import videojs, { VideoJsPlayer } from "video.js";

const PlayPreview: React.FC<any> = ({
  albumTracks,
  trackNo,
  isOpen,
  onClose,
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [player, setPlayer] = useState<VideoJsPlayer>();
  const [showPreview, setShowPreview] = useState(true);
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null);

  const [currentTrackNo, setCurrentTrackNo] = useState(trackNo);

  // console.log('albumTracks>>>>>>',albumTracks)

  useEffect(() => {
    playPreview(trackNo);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (player) {
      player.src([
        {
          src: albumTracks[0].tracks[trackNo].sources[0].src.replace(
            "/master.m3u8",
            "/master-preview.m3u8"
          ),
          type: "application/x-mpegURL",
        },
      ]);
      player.play();
      player.on("ended", () => {
        onClose();
      });
      player.on("error", () => {
        onClose();
      });
    }
  }, [player]);

  useMemo(() => {
    if (videoNode) {
      setPlayer(videojs(videoNode, {}));
    }
  }, [videoNode]);

  const playPreview = (nTrack: number) => {
    setShowPreview(true);
    if (nTrack < 0) {
      nTrack = currentTrackNo;
    } else {
      setCurrentTrackNo(nTrack);
    }

    if (player) {
      alert();
      player.pause();
      player.currentTime(0);
      player.src([
        {
          src: albumTracks[0].tracks[trackNo].sources[0].src.replace(
            "/master.m3u8",
            "/master-preview.m3u8"
          ),
          type: "application/x-mpegURL",
        },
      ]);
      player.play();
    }
  };

  return (
    <>
      <div className=" media-preview">
        <video
          className="video-preview"
          hidden={!showPreview}
          ref={(node) => {
            setVideoNode(node);
          }}
          id="preview_video_hero"
          preload="auto"
          poster="/assets/null.png"
          autoPlay
          muted={isMuted}
          playsInline
          controls
        />
      </div>
      <div
        id="video-mute"
        className="preview_video_sound"
        onClick={() => setIsMuted(!isMuted)}
      >
        <div style={{position:'relative'}}>
          <img
            alt=""
            src={`./assets/img/${isMuted ? "audio-mute.png" : "audio.png"}`}
            width="20"
            className="icon_mute_icon"
          />
        </div>
      </div>
    </>
  );
};

export default PlayPreview;
