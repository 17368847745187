export const TRACK_LOADING = "TRACK_LOADING";
export const TRACK_FAIL = "TRACK_FAIL";
export const TRACK_SUCCESS = "TRACK_SUCCESS";
export const FETCH_TRACKS = "FETCH_TRACKS";

export interface TrackLoading {
  type: typeof TRACK_LOADING
}

export interface TrackFail {
  type: typeof TRACK_FAIL
}

export type TrackType = {
  items: TrackResults[],
}

export type TrackResults = {
  item: {
    id: number,
    title: string,
    title_ar: string,
    artist: string
  }
}

export interface TrackSuccess {
  type: typeof TRACK_SUCCESS,
  payload: TrackType[]
}

export type TrackDispatchTypes = TrackLoading | TrackFail | TrackSuccess