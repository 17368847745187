import { FETCH_ARTISTS, ARTIST_SUCCESS,  } from '../actions/artistActionTypes'
 
const initialState = {
    items: [],
    item: {}
}

const ArtistReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ARTISTS:
            return {
                ...state,
                items: action.payload
            }
        case ARTIST_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        
        default:
            return state;
    }

}

export default ArtistReducer;