import { IonContent } from "@ionic/react";
import axios from "axios";
// import React, { useState } from "react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ModalMessage from "../utilities/ModalMessage";
import { isMobile, isAndroid } from "react-device-detect";

const SignIn = () => {
  let history = useHistory();
  // http://localhost:5000/api/v1/consumer/verify-account?uveid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOjUsIm9yZyI6InRha2VvbmVfZGIiLCJpYXQiOjE2MzUzMTUzNjl9.Z_r9E45g_fItxFcgD88uqclv_nYJyWOE0Y9klrJNEZg
  const [modalMessage, setModalMessage] = useState({ isOpen: false });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyUser = (uveid: any) => {
      // console.log("uveid>>>>", uveid);
      axios({
        method: "post",
        // url: `http://localhost:5000/api/v1/consumer/verify-account?uveid=${uveid}`,
        // url: `https://api.takeone.studio/api/v1/consumer/verify-account?uveid=${uveid}`,
        // url: `http://localhost:5000/api/v1/user/verify-account?uveid=${uveid}`,
        // url: `https://api.takeone.studio/api/v1/user/verify-account?uveid=${uveid}`,
        url: `https://api.takeone.tividad.com/api/v1/user/verify-account?uveid=${uveid}`,
      })
        .then((response: any) => {
          if ([200, 403].includes(response.data.status)) {
            // setMessage(
            //   "Thank you for verifying your email address. You may now sign in."
            // );
            setMessage(response.data.msg);
            setModalMessage({ isOpen: true });

            /*******
             localStorage.setItem("pid", response.data.pid);
             localStorage.setItem("eml", response.data.email);
             localStorage.setItem("uname", response.data.username);
             */

            if (isMobile && isAndroid) {
              window.location.href =
                "https://play.google.com/store/apps/details?id=takeone.studio";
            }

            // history.push("/");
            // window.location.reload();

            ////////history.push("/signin");

            // history.push('/signin-success');
            // window.location.href = "/signin-success";
          }
        })
        .catch((response: any) => {
          //handle error
          // console.log(response);
          // alert(response.data.status + " | " + response.data.msg);
          setMessage(response.data.msg);
          setModalMessage({ isOpen: true });
        });
      ///// history.push("/");
    };
    const verify: any = async (uveid: any) => {
      // console.log("uveid>>>>", uveid);
      await verifyUser(uveid);
      ////// history.push("/signin");
    };

    let params = queryString.parse(window.location.search);
    if (params.uveid) {
      verify(params.uveid);
    }

    // console.log("params>>>>>", params);
  }, [history]);

  return (
    <>
      <IonContent>
        <ModalMessage
          isOpen={modalMessage.isOpen}
          onClose={() => {
            history.push("/");
            setModalMessage({ isOpen: false });
          }}
          message={message}
        />
      </IonContent>
    </>
  );
};

export default SignIn;
