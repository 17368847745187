import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { File } from "@ionic-native/file";
import axios from "axios";
import { Playlist } from "../definitions/MediaInterface";
import { PlatformContext } from "./PlatformContext";
import axiosInstance from "../utilities/axios";

export const LocalPlaylistContext = createContext<any>(null);

export const LocalPlaylistProfider = ({ children }: { children: any }) => {
  const [mylist, setMylist] = useState<Playlist[]>([]);
  const [playlist, setPlaylist] = useState<Playlist[]>([]);
  const [updatePlaylist, setUpdatePlaylist] = useState(false);
  const [updateMylist, setUpdateMylist] = useState(false);
  const { platform } = useContext(PlatformContext);

  // const getMylist = () => {
  //   const path = platform.downloadPath;
  //   let filename = path + "mylist.json";
  //   if (platform.name !== "web") {
  //     filename = Capacitor.convertFileSrc(filename);
  //   }
  //   // alert(filename);
  //   try {
  //     axios.get(filename).then((response) => {
  //       const data: Playlist[] = response.data;
  //       setMylist(data);
  //     });
  //   } catch (error: any) {
  //     console.log("error>>", error);
  //   }
  // };

  const getMylist = () => {
    try {
      const user_id = localStorage.getItem("uid");
      axiosInstance
        .post("media/fetch-user-playlist", { user_id })
        .then((res: any) => {
          // console.log("res.data>>>>>>>>>>>>>", res.data);
          const data: Playlist[] = res.data;
          setMylist(data);
        });
    } catch (error: any) {
      console.log("error>>", error);
    }
  };
  const updateMylistData = (track_id: number, isAdd: boolean) => {
    try {
      const status = isAdd ? "active" : "removed";
      const user_id = localStorage.getItem("uid");
      // alert("status >> " + status);
      axiosInstance
        .post("media/update-user-playlist", { user_id, track_id, status })
        .then((res: any) => {
          // console.log("res.data>>>>>>>>>>>>>", res.data);
          // const data: Playlist[] = res.data;
          // setMylist(data);
        });
    } catch (error: any) {
      console.log("error>>", error);
    }
  };

  const getPlaylist = () => {
    const path = platform.downloadPath;
    let filename = path + "playlist.json";
    if (platform.name !== "web") {
      filename = Capacitor.convertFileSrc(filename);
    }
    // alert(filename)

    // console.log("filename>>>>", filename);
    axios.get(filename).then((response) => {
      const data: Playlist[] = response.data;

      // // console.log(data);
      // const newData = data.filter((item) => {
      //   return item.status === MEDIA_STATUS.DOWNLOADED
      // })
      // setPlaylist(newData);

      setPlaylist(data);
    });
  };

  const providerValue = useMemo(
    () => ({
      playlist,
      setPlaylist,
      updatePlaylist,
      setUpdatePlaylist,
      getPlaylist,
      updateMylistData,
      mylist,
      setMylist,
      updateMylist,
      setUpdateMylist,
      getMylist,
    }),
    [
      playlist,
      setPlaylist,
      updatePlaylist,
      setUpdatePlaylist,
      getPlaylist,
      updateMylistData,
      mylist,
      setMylist,
      updateMylist,
      setUpdateMylist,
      getMylist,
    ]
  );
  useEffect(() => {
    getMylist();
    getPlaylist();
  }, []);

  useEffect(() => {
    if (updatePlaylist) {
      // console.log('updatePlaylist - playlist>>>>', playlist);
      // alert('playlist updated>>' + platform.downloadPath);
      setUpdatePlaylist(false);

      if (platform.name !== "web") {
        //-- write downloads playlist to mobile local folder
        try {
          // File.writeFile(platform.downloadPath, 'playlist.json', JSON.stringify(playlist.filter((item: any) => {
          //   return item.status === MEDIA_STATUS.DOWNLOADED
          // })), { replace: true, });
          File.writeFile(
            platform.downloadPath,
            "playlist.json",
            JSON.stringify(playlist),
            { replace: true }
          );

          // getPlaylist();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [updatePlaylist]);

  // useEffect(() => {
  //   if (updateMylist) {
  //     // console.log('updatePlaylist - playlist>>>>', playlist);
  //     // alert('playlist updated>>' + platform.downloadPath);
  //     setUpdatePlaylist(false);

  //     if (platform.name !== "web") {
  //       //-- write mylist to mobile local folder
  //       try {
  //         File.writeFile(
  //           platform.downloadPath,
  //           "mylist.json",
  //           JSON.stringify(mylist),
  //           { replace: true }
  //         );
  //         // getPlaylist();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }
  // }, [updateMylist]);
  useEffect(() => {
    if (updateMylist) {
      console.log('updateMylist>>>>', updateMylist)
    }
  }, [updateMylist]);

  return (
    <LocalPlaylistContext.Provider value={providerValue}>
      {children}
    </LocalPlaylistContext.Provider>
  );
};
