import { useEffect, useState, useRef, useCallback } from "react";
import { IonModal } from "@ionic/react";
import { AvtPlayer } from "./AvtPlayer";
import "videojs-plus/dist/plugins/unload";
import "../styles/avt-player.css";

import videojs from "video.js";

const playerOptions = {};

const ModalAvtPlayer = (props) => {
  const [player0, setPlayer0] = useState(null);
  const [player1, setPlayer1] = useState(null);
  const [player2, setPlayer2] = useState(null);
  const [video0, setVideo0] = useState(null);
  const [video1, setVideo1] = useState(null);
  const [video2, setVideo2] = useState(null);

  let arrayPlayer = [player0, player1, player2];
  const refPlayer = useRef(player1);
  const [playerName, setPlayerName] = useState("");

  const { playlist, trackNo, isOpen, onClose } = props;
  const onDismiss = () => {
    onClose();
  };

  async function fakeApi(index) {
    // await delay(2000);
    return playlist[index] ? playlist[index] : playlist[0];
  }

  const getArtists = (index) => {
    return playlist[index].artists[0].name;
  };

  const addMediaTitle = useCallback((playr, title) => {
    var elMediaTitle = playr.el_.querySelector(
      ".video-js .vjs-control-bar .vjs-button.video-title"
    );
    const mediaTitle = playr.el_.querySelector(
      ".video-js .vjs-control-bar .vjs-button.video-title .vjs-control-text"
    );

    // console.log('elMediaTitle, mediaTitle >>>>>>>>>>>>>', elMediaTitle, mediaTitle)

    mediaTitle.classList.add("media-title");
    mediaTitle.innerHTML = title;

    while (elMediaTitle.firstChild) {
      elMediaTitle.removeChild(elMediaTitle.lastChild);
    }

    elMediaTitle.appendChild(playr.controlBar.currentTimeDisplay.el_);
    elMediaTitle.appendChild(playr.controlBar.timeDivider.el_);
    elMediaTitle.appendChild(playr.controlBar.durationDisplay.el_);
    elMediaTitle.appendChild(mediaTitle);
    // eslint-disable-next-line
  }, []);

  const addExtraButtons = useCallback(
    (mPlayer) => {
      const Button = videojs.getComponent("Button");
      const CloseButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("close_modal");
        },
        handleClick: function () {
          onDismiss();
        },
      });
      const PrevButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("previous");
          this.controlText("Previous");
        },
        handleClick: function () {
          try {
            playPrevious();
          } catch (error) {
            console.log("error>>>", error);
          }
        },
      });
      const NextButton = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("next");
          this.controlText("Next");
        },
        handleClick: function () {
          try {
            playNext();
          } catch (error) {}
        },
      });

      const VideoTitle = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("video-title");
        },
      });

      const LibraryBox = videojs.extend(Button, {
        constructor: function () {
          Button.apply(this, arguments);
          this.addClass("library-box");
        },
        handleClick: function () {
          try {
            /****
           presentLibrary({ cssClass: "modal-library" });
           */
          } catch (error) {}
        },
      });

      /**** 
    const MediaToggle = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        this.controlText("AUDIO");
        ////////////////////////this.addClass('media_toggle');  //- disabled on 2021-10-24
        this.addClass("with-icon");
        // this.addClass('is-audio');
        // alert('isAudioGlobalTest>>>>'+isAudioGlobalTest);
      },
      handleClick: function () {
        //  isAudioGlobal = !isAudioGlobal;
        //  if (isAudioGlobal) {
        //    this.addClass("is-audio");
        //   } else {
        //     this.el_.classList.remove("is-audio");
        //   }
        //   setIsAudio(isAudioGlobal);
        //   changeMedia();
          
      },
    });
    */
      videojs.registerComponent("PrevButton", PrevButton);
      videojs.registerComponent("NextButton", NextButton);
      videojs.registerComponent("VideoTitle", VideoTitle);
      videojs.registerComponent("LibraryBox", LibraryBox);
      videojs.registerComponent("CloseButton", CloseButton);
      // videojs.registerComponent("MediaToggle", MediaToggle);

      mPlayer.controlBar.addChild("PrevButton", {}, 1);
      mPlayer.controlBar.addChild("NextButton", {}, 2);
      mPlayer.controlBar.addChild("VideoTitle", {}, 3);
      mPlayer.controlBar.addChild("LibraryBox", {}, 10);
      mPlayer.controlBar.addChild("CloseButton", {}, 99);
      // player.controlBar.addChild("MediaToggle", {}, 100);

      addMediaTitle(mPlayer, "");
    },
    // eslint-disable-next-line
    [player1]
  );

  useEffect(() => {
    if (player0 && video0) {
      player0.className = "player0";
      player0.src(video0.sources);
    }
    // eslint-disable-next-line
  }, [video0, player0]);

  useEffect(() => {
    if (player1 && video1) {
      player1.className = "player1";
      player1.src(video1.sources);
    }
    // eslint-disable-next-line
  }, [video1, player1]);

  useEffect(() => {
    if (player2 && video2) {
      player2.className = "player2";
      player2.src(video2.sources);
    }
    // eslint-disable-next-line
  }, [video2, player2]);

  const setTitle = (player, title) => {
    var elMediaTitle = player.el_.querySelector(
      ".video-js .vjs-button.video-title .media-title"
    );
    elMediaTitle.textContent = title;
  };
  const arrayMove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };
  const playPrevious = () => {
    const lastIndex = arrayPlayer.length - 1;
    const currentPlayer = arrayPlayer[0];
    arrayMove(arrayPlayer, lastIndex, 0);

    const currentIndex = parseInt(currentPlayer.tag_);

    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = playlist.length - 1;
    }

    // const title = playlist[currentIndex].title;
    const title = playlist[currentIndex].name + " - " + getArtists(currentIndex);
    setPlayerName(currentPlayer.className);
    setTitle(currentPlayer, title);

    arrayPlayer.map((player) => {
      // player.currentTime(5);
      return player.pause();
    });
    // currentPlayer.currentTime(0);
    currentPlayer.play();
    refPlayer.current = currentPlayer;

    const prevPlayer = arrayPlayer[0];
    setPlayer(prevPlayer, prevIndex);
  };

  const playNext = () => {
    const lastIndex = arrayPlayer.length - 1;
    const currentPlayer = arrayPlayer[lastIndex];
    arrayMove(arrayPlayer, 0, lastIndex);

    let currentIndex = parseInt(currentPlayer.tag_);
    // const title = playlist[currentIndex].title;
    const title = playlist[currentIndex].name + " - " + getArtists(currentIndex);
    setTitle(currentPlayer, title);
    setPlayerName(currentPlayer.className);

    let nextIndex = currentIndex + 1;
    if (nextIndex > playlist.length - 1) {
      nextIndex = 0;
    }

    arrayPlayer.map((player) => {
      // player.currentTime(5);
      return player.pause();
    });
    // currentPlayer.currentTime(0);
    currentPlayer.play();
    refPlayer.current = currentPlayer;

    const nextPlayer = arrayPlayer[lastIndex];
    setPlayer(nextPlayer, nextIndex);
  };

  const setPlayer = (player, index) => {
    if (player.className.includes("player0")) {
      fakeApi(index).then(setVideo0);
      player0.tag_ = index;
    } else if (player.className.includes("player1")) {
      fakeApi(index).then(setVideo1);
      player1.tag_ = index;
      player1.poster("");
    } else if (player.className.includes("player2")) {
      fakeApi(index).then(setVideo2);
      player2.tag_ = index;
    }
  };
  useEffect(() => {
    if (player0) {
      addExtraButtons(player0);
      player0.unload({ loading: true });

      let prevIndex = trackNo - 1;
      if (prevIndex < 0) {
        prevIndex = playlist.length - 1;
      }

      fakeApi(prevIndex).then(setVideo0);
      // player0.currentTime(5);
      player0.pause();
      player0.tag_ = prevIndex;
      player0.name_ = "player0";
      // player0.poster("./assets/img/takeone-logo-square2.jpg");

      const onPlayerEnded = () => {
        playNext();
      };

      player0.on("ended", onPlayerEnded);

      return () => {
        player0.off("ended", onPlayerEnded);
      };
    }
    // eslint-disable-next-line
  }, [player0]);

  useEffect(() => {
    if (player1) {
      refPlayer.current = player1;
      addExtraButtons(player1);
      player1.unload({ loading: true });

      fakeApi(trackNo).then(setVideo1);

      setPlayerName("player1");
      // setTitle(player1, playlist[trackNo].title);
      const title = playlist[trackNo].name + " - " + getArtists(trackNo);
      setTitle(player1, title);
      // player1.currentTime(0);
      player1.play();
      player1.tag_ = trackNo;
      player1.name_ = "player1";
      player1.poster("./assets/img/takeone-logo-square2.jpg");

      const onPlayerEnded = () => {
        playNext();
      };

      player1.on("ended", onPlayerEnded);

      return () => {
        player1.off("ended", onPlayerEnded);
      };
    }
    // eslint-disable-next-line
  }, [player1]);

  useEffect(() => {
    if (player2) {
      addExtraButtons(player2);
      player2.unload({ loading: true });

      let nextIndex = trackNo + 1;
      if (nextIndex > playlist.length - 1) {
        nextIndex = 0;
      }

      fakeApi(nextIndex).then(setVideo2);
      player2.currentTime(5);
      player2.pause();
      player2.tag_ = nextIndex;
      player2.name_ = "player2";
      // player2.poster("./assets/img/takeone-logo-square2.jpg");

      const onPlayerEnded = () => {
        playNext();
      };

      player2.on("ended", onPlayerEnded);

      return () => {
        player2.off("ended", onPlayerEnded);
      };
    }
    // eslint-disable-next-line
  }, [player2]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} className="modal-player">
      <div>
        <AvtPlayer
          playerOptions={playerOptions}
          onPlayerInit={setPlayer0}
          onPlayerDispose={setPlayer0}
          isVisible={playerName.includes("player0")}
        />
        <AvtPlayer
          playerOptions={playerOptions}
          onPlayerInit={setPlayer1}
          onPlayerDispose={setPlayer1}
          isVisible={playerName.includes("player1")}
        />
        <AvtPlayer
          playerOptions={playerOptions}
          onPlayerInit={setPlayer2}
          onPlayerDispose={setPlayer2}
          isVisible={playerName.includes("player2")}
        />
      </div>
      {/* {console.log("playlist>>>>", playlist)} */}
    </IonModal>
  );
};

export default ModalAvtPlayer;
