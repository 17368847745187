export const ARTIST_LOADING = "ARTIST_LOADING";
export const ARTIST_FAIL = "ARTIST_FAIL";
export const ARTIST_SUCCESS = "ARTIST_SUCCESS";
export const FETCH_ARTISTS = "FETCH_ARTISTS";

export interface ArtistLoading {
  type: typeof ARTIST_LOADING
}

export interface ArtistFail {
  type: typeof ARTIST_FAIL
}

export type ArtistType = {
  items: ArtistResults[],
}

export type ArtistResults = {
  item: {
    id: number,
    title: string,
    album: object,
    artists: object
  }
}

export interface ArtistSuccess {
  type: typeof ARTIST_SUCCESS,
  payload: ArtistType[]
}

export type ArtistDispatchTypes = ArtistLoading | ArtistFail | ArtistSuccess