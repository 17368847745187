import { IonContent, IonIcon, IonButton } from "@ionic/react";
import { play, trash } from "ionicons/icons";
import { useContext, useRef, useState } from "react";
import SearchModal from "../components/SearchModal";
import UserAccessModal from "../components/UserAccessModal";
import { LocalPlaylistContext } from "../contexts/LocalPlaylistContext";
import { Playlist } from "../definitions/MediaInterface";
// import MediaPlayer from "../utilities/MediaPlayer";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";
import { File } from "@ionic-native/file";
import { PlatformContext } from "../contexts/PlatformContext";

const convertDuration = (str: string) => {
  if (str)
    return str.split(":")[0] + " min " + parseInt(str.split(":")[1]) + " sec";
  else return "";
};
const Downloads: React.FC = () => {
  // const [showMediaPlayer, setShowMediaPlayer] = useState({ isOpen: false });
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const { playlist, setPlaylist, setUpdatePlaylist } =
    useContext(LocalPlaylistContext);
  const { platform } = useContext(PlatformContext);
  const [trackNo, setTrackNo] = useState(0);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [modalUserAccess, setModalUserAccess] = useState({ isOpen: false });
  const refUserModal = useRef<any>();

  const showSearch = () => {
    if (localStorage.getItem("access_token")) {
      setShowSearchModal(true);
    } else {
      refUserModal.current = setModalUserAccess({ isOpen: true });
    }
  };

  const onDelete = (track: any) => {
    try {
      const folder = track.sources[0].src.replaceAll("/video/index.m3u8", "");
      // const folderSplit = folder.split("downloads/");
      const folderName = folder.split("downloads/")[1];

      File.removeRecursively(platform.downloadPath, folderName);

      const newPlaylist = playlist.filter((t: any) => t !== track);
      setPlaylist(newPlaylist);
      setUpdatePlaylist(true);
    } catch (error: any) {
      console.log("error>>>", error);
    }
  };
  return (
    <>
      {/* <IonHeader className="top-header ion-no-border" >
        <IonToolbar>
          <IonTitle>Downloads</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <div
          className="modal-page-content pad-10"
          style={{ marginTop: "80px" }}
        >
          <div className="size-16">Downloads</div>
          {playlist && playlist?.length > 0 ? (
            playlist ? (
              playlist.map((track: Playlist, index: number) => {
                return (
                  <div className="pad-20-bottom" key={index}>
                    {/* {console.log('track>>>',track)} */}
                    <div className="grid album-track">
                      <div
                        className="play-track"
                        onClick={() => {
                          setTrackNo(index);
                          // setShowMediaPlayer({ isOpen: true });
                          setShowMediaPlayer(true);
                        }}
                      >
                        <img
                          src={track.poster}
                          alt="image"
                          height="60"
                          width="80"
                        />
                        <IonIcon className="play-center" icon={play} />
                        <div className="track-duration size-10">
                          {" "}
                          {track.duration}
                        </div>
                      </div>
                      <div className="at-left">
                        <div className="pad-10-top">
                          {track.name_ar ? track.name_ar : track.name}
                        </div>
                        <div className="size-12  color-aaa">
                          {track.artist}
                          {/* {convertDuration(track.duration)} */}
                        </div>
                      </div>

                      <IonIcon
                        icon={trash}
                        size="small"
                        className="pad-10-top cursor-pointer"
                        onClick={() => onDelete(track)}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="pad-20-top">No music video downloaded yet.</div>
            )
          ) : (
            <div className="pad-20-top">No music video downloaded yet.</div>
          )}

          {/* <MediaPlayer
            tracks={playlist}
            trackNo={trackNo}
            isOpen={showMediaPlayer.isOpen}
            onClose={() => setShowMediaPlayer({ isOpen: false })}
          /> */}
          {showMediaPlayer && (
            <ModalAvtPlayer
              playlist={playlist}
              trackNo={trackNo}
              isOpen={showMediaPlayer}
              onClose={() => setShowMediaPlayer(false)}
            />
          )}
        </div>
        <div hidden={true} className="at-center">
          <IonButton color="secondary" onClick={() => showSearch()}>
            Find more to download
          </IonButton>
        </div>
      </IonContent>

      <UserAccessModal
        isOpen={modalUserAccess.isOpen}
        onClose={() => {
          showSearch();
          setModalUserAccess({ isOpen: false });
        }}
      ></UserAccessModal>

      <SearchModal
        isOpen={showSearchModal}
        onClose={() => setShowSearchModal(false)}
      />
    </>
  );
};

export default Downloads;
