import {
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
} from "@ionic/react";
// import axios from "axios";
import {
  // checkmarkOutline,
  // closeOutline,
  checkmarkCircle,
  closeCircle,
} from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateDocStatus,
  updateDocStatusAll,
} from "../actions/userActions";
import { PlatformContext } from "../contexts/PlatformContext";
// import { RootStore } from "../store";
import axiosInstance from "../utilities/axios";
// import DocumentViewer from "../utilities/DocumentViewer";
import PdfViewer from "../utilities/PdfViewer";
import ReactFileViewer from "../utilities/ReactFileViewer";

const slideOpts = {
  initialSlide: 0,
  // slidesPerView: 5,
  slidesPerView: "auto",
  speed: 400,
  // speed: 200,
  // loop:true,
  freeMode: true,
};

// const defaultPath = "https://api.takeone.studio/user";
const defaultPath = "https://api.takeone.tividad.com/user";
// const defaultPath = "http://localhost:5000/user";

const DashboardAdmin: React.FC<any> = () => {
  const { platform } = useContext(PlatformContext);
  // const users = useSelector((state: RootStore) => state.allUsers.items);
  // const users = useSelector((state: RootStore) => state.allUsers.items);
  const [users, setUsers] = useState<any>([]);

  const [modalFileViewer, setModalFileViewer] = useState({ isOpen: false });
  const dispatch = useDispatch();
  const [docStatus, setDocStatus] = useState<string>("pending");
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);

  const refUsers = useRef<any>([]);
  const refDocStatus = useRef<string>("pending");

  const refFile = useRef<string>(
    `${defaultPath}/adonis@tividad.com/avtividad-3hX3Ewgn9NB4HWBk9LACyU.pdf`
  );

  // const refUserFiles = useRef<any>();
  const refSrcPath = useRef<string>(`${defaultPath}/adonis@tividad.com`);

  useEffect(() => {
    setIsUpdateData(!isUpdateData);
    // dispatch(fetchUsers(refDocStatus.current));
    // dispatch(fetchDocFiles());
  }, []);

  // useEffect(() => {
  //   console.log('isUpdateData>>>',isUpdateData)
  //   const timerId = setTimeout(() => {
  //     // dispatch(fetchUsers(refDocStatus.current));
  //     const searchTerm = async () => {
  //     const res = await axios.post(
  //       "http://localhost:5000/api/v1/user/fetch-users",{
  //         doc_status
  //       }
  //     );
  //   },500);

  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, [isUpdateData]);
  useEffect(() => {
    const getUsers = async () => {
      const doc_status = refDocStatus.current;
      const condition = { status: refDocStatus.current };
      axiosInstance.post("user/fetch-userfiles", condition).then((res: any) => {
        setUsers([]);
        // console.log("res.data.results>>>>>>", doc_status, res.data.results);

        setUsers(res.data.results);
      });
    };

    // console.log("isUpdateData>>>", isUpdateData);
    const timerId = setTimeout(() => {
      getUsers();
    }, 500);
    return () => {
      clearTimeout(timerId);
    };
  }, [isUpdateData]);

  const onClickFileIcon = (user: any, index: number) => {
    refFile.current = `${defaultPath}/${user?.email}/${user?.users_files[index].filename}`;
    refSrcPath.current = `${defaultPath}/${user?.email}/`;
    // refUserFiles.current = user?.users_files;
    setModalFileViewer({ isOpen: true });

    // console.log("refFile.current>>>", refFile.current, user);
  };

  const getFileExtension: any = (filename: string) => {
    const re = /(?:\.([^.]+))?$/;
    const test = re.exec(filename);
    if (test) {
      return test[1];
    } else {
      return "";
    }
  };

  const approveDocument = (user: any, id: string) => {
    dispatch(updateDocStatus(id, platform.email, "approved"));
    setIsUpdateData(!isUpdateData);
  };
  const rejectDocument = (user: any, id: string) => {
    dispatch(updateDocStatus(id, platform.email, "rejected"));
    setIsUpdateData(!isUpdateData);
  };

  const approveDocumentAll = (email: string) => {
    // console.log("approveDocumentAll>>>>", email);
    dispatch(updateDocStatusAll(email, "approved"));
    setIsUpdateData(!isUpdateData);
    // setIsUpdateData(!isUpdateData);
  };

  const rejectDocumentAll = (email: string) => {
    // console.log("rejectDocumentAll>>>>", email);
    dispatch(updateDocStatusAll(email, "rejected"));
    setIsUpdateData(!isUpdateData);
    setIsUpdateData(!isUpdateData);
  };
  const onChangeStatusTab = (value: any) => {
    // console.log("Segment selected", value);
    refDocStatus.current = value;
    // setUsers([]);
    setIsUpdateData(!isUpdateData);
    // dispatch(fetchUsers(value));
  };

  const showUserFiles = (user: any) => {
    // console.log("user?.users_file.length>>>>", user?.users_files.length);
    try {
      if (user?.users_files.length > 0) {
        return (
          <div>
            <IonSlides>
              {user?.users_files.map((file: any, ndx: number) => {
                return (
                  <IonSlide key={ndx} className="doc-file">
                    <div>
                      <img
                        src={`./assets/img/${getFileExtension(
                          file.filename
                        )}.png`}
                        alt="icon"
                        // height="80"
                        onClick={() => onClickFileIcon(user, ndx)}
                      />
                      <div
                        className="size-10"
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "60px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                      >
                        {file.filename}

                        {file.status === "pending" && (
                          <div className="approve-reject">
                            <IonIcon
                              // size="small"
                              className="approve "
                              icon={checkmarkCircle}
                              color="success"
                              onClick={() => approveDocument(user, file.id)}
                            />
                            &nbsp;
                            <IonIcon
                              // size="small"
                              className="reject "
                              icon={closeCircle}
                              color="danger"
                              onClick={() => rejectDocument(user, file.id)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </IonSlide>
                );
              })}
            </IonSlides>
          </div>
        );
      } else {
        return (
          <div>
            <img
              src="./assets/img/pdf.png"
              alt="icon"
              height="80"
              style={{ opacity: ".1" }}
            />
            <div className="size-10">No Files </div>
          </div>
        );
      }
    } catch (error) {}
  };

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <br />
      <br />
      <br />
      <br />
      <br />

      <IonSegment
        scrollable
        onIonChange={(e) => onChangeStatusTab(e.detail.value)}
        value={refDocStatus.current}
        style={{ maxWidth: "500px" }}
      >
        <IonSegmentButton value="pending">
          <IonLabel>Pending</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="approved">
          <IonLabel>Approved</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="rejected">
          <IonLabel>Rejected</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      <div className="grid-columns pad-20">
        {users &&
          users?.map((user: any, index: any) => {
            return (
              <div className="result-item pad-10 at-center" key={index}>
                <div>
                  {user.first_name} {user.last_name}{" "}
                </div>

                {showUserFiles(user)}

                {/* {refDocStatus.current === "pending" && (
                  <div>
                    <IonButton
                      className="size-12 color-aaa"
                      onClick={() => approveDocumentAll(user?.email)}
                      disabled={user?.files?.length <= 0}
                    >
                      <IonIcon
                        size="small"
                        className="approve "
                        icon={checkmarkOutline}
                        color="success"
                      />
                      Approve
                    </IonButton>
                    <IonButton
                      className="size-12 color-aaa"
                      onClick={() => rejectDocumentAll(user?.email)}
                      disabled={user?.files?.length <= 0}
                    >
                      <IonIcon
                        size="small"
                        className="reject "
                        icon={closeOutline}
                        color="danger"
                      />
                      Reject
                    </IonButton>
                  </div>
                )} */}
              </div>
            );
          })}
      </div>

      <>
        {refFile.current &&
          (refFile.current?.toLowerCase().includes(".pdf") ? (
            <PdfViewer
              isOpen={modalFileViewer.isOpen}
              onClose={() => {
                setModalFileViewer({ isOpen: false });
              }}
              file={refFile.current}
            />
          ) : (
            <ReactFileViewer
              isOpen={modalFileViewer.isOpen}
              onClose={() => {
                setModalFileViewer({ isOpen: false });
              }}
              file={refFile.current}
            />
          ))}
        {/* <div className='file-viewer-buttons'>
          <IonButton
            className="size-12 color-aaa"
            // onClick={() => approveDocumentAll(user?.email)}
            // disabled={user?.files?.length <= 0}
          >
            <IonIcon
              size="small"
              className="approve "
              icon={checkmarkOutline}
              color="success"
            />
            Approve
          </IonButton>
          <IonButton
            className="size-12 color-aaa"
            // onClick={() => rejectDocumentAll(user?.email)}
            // disabled={user?.files?.length <= 0}
          >
            <IonIcon
              size="small"
              className="reject "
              icon={closeOutline}
              color="danger"
            />
            Reject
          </IonButton>
        </div> */}
      </>
      {/* {refFile.current && (
        <DocumentViewer
          isOpen={modalFileViewer.isOpen}
          onClose={() => {
            setModalFileViewer({ isOpen: false });
          }}
          srcPath={refSrcPath.current}
          files={refUserFiles.current}
        />
      )} */}
    </div>
  );
};

export default DashboardAdmin;
