import {IonIcon, IonModal } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

const ModalMessage: React.FC<any> = ({ isOpen, onClose, message }) => {
  const onDismiss = () => {
    onClose(1);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      cssClass="modal-message user-sign"
    >
      <div className='at-center'>
        <IonIcon
          onClick={onClose}
          className="modal-close close cursor-pointer"
          icon={closeCircleOutline}
          size="large"
        />
        <img src="../img/checked.png" alt="" width="80" />
       
        <div className='message'>{message}</div>
      </div>
    </IonModal>
  );
};

export default ModalMessage;
