import {
  IonIcon,
  IonInput,
  IonModal,
  // IonHeader,
  // IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../utilities/axios";
// import { UserContext } from "../contexts/UserContext";
import { ACCESS_TYPE } from "../definitions";
import ModalMessage from "../utilities/ModalMessage";
import { PlatformContext } from "../contexts/PlatformContext";
import { arrowBack, eyeOffOutline, eyeOutline } from "ionicons/icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import UserOTP from "./UserOTP";
// import { platform } from "os";

const validEmail = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const UserAccessModal: React.FC<any> = ({ isOpen, onClose }) => {
  const { platform, setPlatform } = useContext(PlatformContext);
  // const { user, setUser } = useContext(UserContext);
  // const refEmail = useRef<HTMLIonInputElement | undefined | null>(null);
  // const refEmail = useRef<any>();
  // const refFirstName = useRef<any>();
  // const refLastName = useRef<any>();
  // const refPassword = useRef<any>();
  const [modalMessage, setModalMessage] = useState({ isOpen: false });
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [modalOTP, setModalOTP] = useState({ isOpen: false });
  const [showSendOTP, setShowSendOTP] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [accessType, setAccessType] = useState<ACCESS_TYPE>(
    ACCESS_TYPE.SIGN_IN
  );
  // const [accessType, setAccessType] = useState<ACCESS_TYPE>(ACCESS_TYPE.SIGN_UP)
  // const [nationality, setNationality] = useState<string>("");
  // const [country, setCountry] = useState<string>("");
  const errMessageInitial = {
    first_name: "",
    last_name: "",
    password: "",
    // confirm_password: "_",
    email: "",
    city: "",
    country: "",
  };
  const [userInfo, setUserInfo] = useState<any>(errMessageInitial);
  const [errMessage, setErrMessage] = useState<any>(errMessageInitial);

  const onDismiss = () => {
    setAccessType(ACCESS_TYPE.SIGN_IN);
    onClose(1);
  };
  useEffect(() => {
    setErrorMessage("");
  }, [accessType]);

  // useEffect(() => {
  //   if (refEmail && refEmail.current) {
  //     try {
  //       setTimeout(() => refEmail.current.setFocus(), 500);
  //     } catch (error) {}
  //   }
  // }, []);

  // useEffect(() => {
  //   if (refEmail && refEmail.current) {
  //     refEmail.current.value = "";
  //   }
  //   if (refFirstName && refFirstName.current) {
  //     refFirstName.current.value = "";
  //   }
  //   if (refLastName && refLastName.current) {
  //     refLastName.current.value = "";
  //   }

  //   if (refPassword.current) {
  //     refPassword.current.value = "";
  //   }
  //   refEmail.current?.focus();
  // }, [accessType]);

  // const sendOTP = () => {
  //   axiosInstance
  //     .post("/user/send-otp", {
  //       email: "avtividad@yahoo.com",
  //     })
  //     .then((response: any) => {
  //       if ([200, 403].includes(response.data.status)) {
  //       }
  //     });
  // };

  // https://takeone.meekd.org/auth/register/
  const userRegister = () => {
    // setErrorMessage("");
    // setErrMessage({ ...errMessage, errMessageInitial });
    // if (refFirstName.current.value === "") {
    //   setTimeout(() => refFirstName.current.setFocus(), 500);
    //   setErrorMessage("First Name is required.");
    //   setErrMessage({ ...errMessage, first_name: "This field is required" });

    //   return;
    // }
    // if (refLastName.current.value === "") {
    //   setTimeout(() => refLastName.current.setFocus(), 500);
    //   setErrorMessage("Last Name is required.");
    //   return;
    // }
    // if (refEmail.current.value === "") {
    //   setTimeout(() => refEmail.current.setFocus(), 500);
    //   setErrorMessage("Email is required.");
    //   return;
    // }
    // if (refEmail.current.value !== "undefined") {
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(refEmail.current.value)) {
    //     setTimeout(() => refEmail.current.setFocus(), 500);
    //     setErrorMessage("Please enter valid email address.");
    //     return;
    //   }
    // }

    // if (refPassword.current.value === "") {
    //   setTimeout(() => refPassword.current.setFocus(), 500);
    //   setErrorMessage("Password is required.");
    //   return;
    // }

    axiosInstance
      // .post(`consumer/signup/`, {
      .post(`user/signup/`, userInfo)
      .then((res) => {
        // history.push('/signin');
        // console.log("res>>>>>>>>>", res);

        if (res.data.status === 200) {
          /****
            // console.log(res.data);
            setAccessType(ACCESS_TYPE.SIGN_IN);
             // setUser(res.data);
            setMessage(
             "A verification link has been sent to your email account. Please click on the link to verify your email. Make sure to check your Spam / Junk folder. "
             );
            setModalMessage({ isOpen: true });
          */
          // setAccessType(ACCESS_TYPE.OTP_VERIFY);

          // sendOTP();
          setModalOTP({ isOpen: true });
        } else {
          // switch (res.data.field) {
          //   case "email":
          //     refEmail.current?.focus();
          //     break;
          //   case "first_name":
          //     refFirstName.current?.focus();
          //     break;
          //   case "last_name":
          //     refLastName.current?.focus();
          //     break;
          //   default:
          //     break;
          // }
          setErrorMessage(res.data.msg);
        }
      });
  };

  const userSignIn = () => {
    setErrorMessage("");
    axiosInstance.post(`user/signin/`, userInfo).then((res) => {
      // console.log(res.data);

      if (res.data.status === 200) {
        if (res.data.is_verified) {
          setPlatform({
            ...platform,
            username: res.data.username,
            pid: res.data.pid,
          });
          localStorage.setItem("uid", res.data.id);
          localStorage.setItem("pid", res.data.pid);
          localStorage.setItem("eml", res.data.email);
          localStorage.setItem("uname", res.data.username);
          // window.location.href = "/";
          onClose(1);
        } else {
          setShowSendOTP(true);
          setErrorMessage(
            "Account is not yet verified. Please check your email for verification. "
          );
        }
      } else {
        setErrorMessage(res.data.msg);
      }

      // if (res) {
      //   localStorage.setItem("access_token", res.data.access);
      //   localStorage.setItem("refresh_token", res.data.refresh);
      //   axiosInstance.defaults.headers["Authorization"] =
      //     "JWT " + localStorage.getItem("access_token");
      //   // history.push('/');
      //   setUser(res.data);
      //   console.log(res.data);
      //   onClose(1);
      // }
    });
  };

  const validate = (name: any, val: any) => {
    console.log("ErrMessage>>>>>>", errMessage);
    if (name === "email" && validEmail(val)) {
      setErrMessage({ ...errMessage, [name]: "Invalid email address" });
      return false;
    }
    if (val === "") {
      setErrMessage({ ...errMessage, [name]: "This field is required" });
      return false;
    } else if (val.length < 3) {
      setErrMessage({ ...errMessage, [name]: "Must be at least 3 letters" });
      return false;
    } else {
      setErrMessage({ ...errMessage, [name]: "" });
    }

    // let isValid = true;
    // Object.entries(userInfo).map((info: any) => {
    //   const name = info[0],
    //     val = info[1];
    //   if (val === "") {
    //     isValid = false;
    //   }
    // });

    // setButtonDisabled(!isValid);
    // console.log('isValid>>>>', isValid);
  };

  const handleChange: any = (e: any, val: any) => {
    const name = e.target.name;
    setUserInfo({ ...userInfo, [name]: e.target.value });
    // console.log("e>>>>", val, e.target.name);
    validate(name, val);
  };

  useEffect(() => {
    // console.log("userInfo>>>>>>", userInfo);
    let isValid = true;
    Object.entries(userInfo).map((info: any) => {
      const name = info[0],
        val = info[1];
      if (val === "" || val.length < 3) {
        // setErrMessage({ ...errMessage, [name]: "This field is required" });
        isValid = false;
      }
      if (name === "email" && validEmail(val)) {
        isValid = false;
      }
    });

    setButtonDisabled(!isValid);
  }, [userInfo]);

  const onReset = () => {
    // console.log("userInfo>>>>", userInfo);
    let url = "user/forgot-password";
    if (accessType === ACCESS_TYPE.RESET_PASSWORD) {
      url = "user/reset-password2";
    }
    axiosInstance
      .post(url, JSON.stringify(userInfo))
      .then(function (response) {
        //handle success
        // console.log(response);
        // history.push('/signin-success');
        setErrorMessage(response.data.msg);
      })
      .catch(function (response) {
        //handle error
        // console.log(response);
        setErrorMessage(response.data.msg);
      });
  };
  const resendOTP = () => {
    setErrorMessage("");
    const email = userInfo.email;
    axiosInstance
      .post("/user/send-otp", {
        email,
      })
      .then((res: any) => {
        if ([200, 403].includes(res.data.status)) {
          setModalOTP({ isOpen: true });
        }
      });
  };
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      cssClass="user-sign"
      // backdropDismiss={false}

    >
      
      <IonIcon
        slot="start"
        onClick={onClose}
        className="back cursor-pointer size-20 pad-10-lr"
        icon={arrowBack}
        style={{ position: "absolute", top: "40px", left: "20px" }}
      />

      {/* {console.log(">>>>>>>>", userInfo)} */}
      <div>
        <div className="at-bold size-28 at-center pad-20-top">
          {accessType === ACCESS_TYPE.RESET_PASSWORD
            ? " RESET PASSWORD"
            : accessType === ACCESS_TYPE.FORGOT_PASSWORD
            ? " Forgot Password"
            : accessType === ACCESS_TYPE.SIGN_UP
            ? " SIGN UP"
            : " SIGN IN"}
        </div>
        <br />

        {accessType === ACCESS_TYPE.SIGN_UP && (
          <div className="item-row row-2">
            <div className="sign-field">
              <IonInput
                name="first_name"
                onIonChange={(e) => handleChange(e, e.detail.value!)}
                // ref={(ref) => (refFirstName.current = ref)}
                placeholder="First Name*"
                className="pad-10"
              ></IonInput>
              {errMessage.first_name && (
                <div className="err-message">{errMessage.first_name}</div>
              )}
            </div>
            <div className="sign-field">
              <IonInput
                name="last_name"
                onIonChange={(e) => handleChange(e, e.detail.value!)}
                // ref={(ref) => (refLastName.current = ref)}
                placeholder="Last Name*"
                className="pad-10"
              ></IonInput>
              {errMessage.last_name && (
                <div className="err-message">{errMessage.last_name}</div>
              )}
            </div>
          </div>
        )}
        {accessType !== ACCESS_TYPE.RESET_PASSWORD && (
          <div className="item-row">
            <div className="sign-field">
              <IonInput
                // autofocus={true}
                name="email"
                onIonChange={(e) => handleChange(e, e.detail.value!)}
                // ref={(ref) => (refEmail.current = ref)}
                // value="avtividad@yahoo.com"
                placeholder="Email Address*"
                className="pad-10"
              ></IonInput>
              {errMessage.email && (
                <div className="err-message">{errMessage.email}</div>
              )}
            </div>
          </div>
        )}
        {(accessType === ACCESS_TYPE.SIGN_IN ||
          accessType === ACCESS_TYPE.RESET_PASSWORD ||
          accessType === ACCESS_TYPE.SIGN_UP) && (
          <div className="item-row">
            <div className="sign-field">
              <IonInput
                name="password"
                onIonChange={(e) => handleChange(e, e.detail.value!)}
                // ref={(ref) => (refPassword.current = ref)}
                // value="adonis123"
                // onIonChange={(val:any, e:any) => handleChange(e, val)}
                placeholder="Password*"
                type={showPassword ? "text" : "password"}
                className="pad-10 ion-align-self-end"
              >
                <IonIcon
                  slot="end"
                  size="small"
                  className="approve curosr-pointer pad-10-lr ion-align-self-end"
                  icon={showPassword ? eyeOutline : eyeOffOutline}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </IonInput>
            </div>
          </div>
        )}
        {accessType === ACCESS_TYPE.SIGN_UP && (
          <div className="item-row row-2">
            <div className="sign-field">
              <CountryDropdown
                // placeholder={lang==='ar'?"":""}
                name="country"
                value={userInfo.country}
                onChange={(val, e) => handleChange(e, val)}
              />
              {/* <div className="err-message">test</div> */}
            </div>
            <div className="sign-field">
              <RegionDropdown
                name="city"
                country={userInfo.country}
                value={userInfo.city}
                onChange={(val, e) => handleChange(e, val)}
              />
              {/* <div className="err-message">test</div> */}
            </div>
          </div>
        )}

        {accessType === ACCESS_TYPE.RESET_PASSWORD && (
          <div className="item-row">
            <div className="sign-field">
              <IonInput
                name="confirm_password"
                onIonChange={(e) => handleChange(e, e.detail.value!)}
                // ref={(ref) => (refPasswordConfirm.current = ref)}
                // value="adonis123"
                // onIonChange={(val:any, e:any) => handleChange(e, val)}
                placeholder="Confirm Password*"
                type={showPassword ? "text" : "password"}
                className="pad-10 ion-align-self-end"
              >
                <IonIcon
                  slot="end"
                  size="small"
                  className="approve curosr-pointer pad-10-lr ion-align-self-end"
                  icon={showPassword ? eyeOffOutline : eyeOutline}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </IonInput>
            </div>
          </div>
        )}
        {errorMessage && (
          <div className="at-center error-message">
            {errorMessage}
            {showSendOTP && (
              <a
                className="cursor-pointer at-bold color-666"
                onClick={() => resendOTP()}
              >
                <u>Resend OTP</u>
              </a>
            )}
          </div>
        )}
        {/* <br /> */}

        {[ACCESS_TYPE.FORGOT_PASSWORD, ACCESS_TYPE.RESET_PASSWORD].includes(
          accessType
        ) ? (
          <div className="info at-center">
            <button color="tertiary" onClick={() => onReset()}>
              Reset Password
            </button>
            <br />
            <a
              className="cursor-pointer at-bold color-666"
              onClick={() => setAccessType(ACCESS_TYPE.SIGN_IN)}
            >
              <u>Sign In.</u>
            </a>
          </div>
        ) : accessType === ACCESS_TYPE.SIGN_UP ? (
          <div className="info at-center">
            <div>
              <a href="https://artist.takeone.studio/tc/" target="_blank">
                <span>
                  By clicking the submit button, you are agreeing to the{" "}
                  <span style={{ color: "blue" }}>Terms and Conditions</span> of
                  TakeOne.{" "}
                </span>
              </a>
            </div>
            <br />
            <button
              disabled={buttonDisabled}
              color="tertiary"
              onClick={() => userRegister()}
            >
              Sign Up
            </button>
            <br />
            <label> Already have an account, </label> &nbsp;
            <a
              className="cursor-pointer at-bold color-666"
              onClick={() => setAccessType(ACCESS_TYPE.SIGN_IN)}
            >
              <u>Sign In.</u>
            </a>
          </div>
        ) : (
          <div className="at-center">
            <button color="tertiary" onClick={() => userSignIn()}>
              Sign In
            </button>
            {/**** disabled temporarily - 
             * send pin code to email (nodejs) 
             * ask for pin code
             * check if code is correct
             * proceed to change password / confirm password  
            <br />
            <a
              className="cursor-pointer at-bold color-666"
              onClick={() => setAccessType(ACCESS_TYPE.FORGOT_PASSWORD)}
            >
              Forgot Password?
            </a> */}
            <br />
            <label>Don't have any account yet? </label> &nbsp;
            <a
              className="cursor-pointer at-bold color-666"
              //-- temporarily disabled - 2021-12-02
              onClick={() => setAccessType(ACCESS_TYPE.SIGN_UP)}
            >
              <u>Sign up</u>
            </a>
            {platform.name !== "web" && (
              <>
                <br />
                <label>Are you an artist? </label> &nbsp;
                <br />
                <a
                  className="cursor-pointer at-bold color-666"
                  onClick={() =>
                    (window.location.href =
                      "https://artist.takeone.studio/signup")
                  }
                >
                  <u>Sign up as an artist</u>
                </a>
                <label> on TakeOne Web site </label>
              </>
            )}
          </div>
        )}
      </div>
      <ModalMessage
        isOpen={modalMessage.isOpen}
        onClose={() => {
          setModalMessage({ isOpen: false });
        }}
        message={message}
      />
      <UserOTP
        isOpen={modalOTP.isOpen}
        onClose={() => {
          setModalOTP({ isOpen: false });
        }}
        email={userInfo.email}
        setAccessType={setAccessType}
      />
      {/* <button onClick={() => setAccessType(ACCESS_TYPE.RESET_PASSWORD)}>
        test
      </button> */}
    </IonModal>
  );
};

export default UserAccessModal;
