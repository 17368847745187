import {
  createAnimation,
  IonHeader,
  IonIcon,
  // IonInput,
  IonModal,
  IonToolbar,
  IonContent,
} from "@ionic/react";
import { arrowBack, search } from "ionicons/icons";
import React, {  useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { PlatformContext } from "../contexts/PlatformContext";

import { RootStore } from "../store";
// import MediaPlayer from "../utilities/MediaPlayer";
import ModalAvtPlayer from "../utilities/ModalAvtPlayer";
import UserAccessModal from "./UserAccessModal";

const SearchModal: React.FC<any> = ({ isOpen, onClose }) => {
  // const { platform } = useContext(PlatformContext);
  const refSearch = useRef<any>();
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  const allTracks = useSelector((state: RootStore) => state.allTracks.items);
  const [showMediaPlayer, setShowMediaPlayer] = useState<boolean>(false);
  const [tracks, setTracks] = useState<any>();
  const refTrackNo = useRef<number>(0);

  const [modalUserAccess, setModalUserAccess] = useState({ isOpen: false });

  const onDismiss = () => {
    onClose(1);
  };

  useEffect(() => {
    if ((isOpen || modalUserAccess.isOpen) && refSearch && refSearch.current) {
      // setTimeout(() => refSearch.current.setFocus(), 500);
    }
    // eslint-disable-next-line
  }, [isOpen, modalUserAccess.isOpen]);

  const enterAnimation = (baseEl: any) => {
    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector(".modal-wrapper")!)
      .fromTo("transform", "translateX(100vw)", "translateX(0px)")
      .fromTo("opacity", ".8", "1");

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(200)
      .addAnimation([wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: any) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  useEffect(() => {
    if (isOpen) {
      const timerId = setTimeout(() => {
        setDebouncedTerm(term);
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
    // eslint-disable-next-line
  }, [term]);

  useEffect(() => {
    if (isOpen) {
      const searchTerm = async () => {
        let info: any = allTracks.filter((track: any) => {
          // let found = true;
          return debouncedTerm
            .trim()
            .split(" ")
            .every((term: string) => {
              return (
                track.name.toLowerCase().includes(term.toLowerCase()) ||
                track.artists[0].name.toLowerCase().includes(term.toLowerCase())
              );
            });
        });
        setTracks(info);
      };
      if (debouncedTerm) searchTerm();
      else setTracks([]);
    }
    // eslint-disable-next-line
  }, [debouncedTerm]);

  const playMedia = (index: number) => {
    refTrackNo.current = index;
    setShowMediaPlayer(true);
  };
  // const gotoSignIn = () => {
  //   const url = "https://artist.takeone.studio/signin";
  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onDismiss}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="modal-search"
      >
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonIcon
              slot="start"
              onClick={onClose}
              className="back cursor-pointer size-20 pad-10-lr"
              icon={arrowBack}
            />
            {/* <IonIcon
              onClick={() => setModalUserAccess({ isOpen: true })}
              className="cursor-pointer"
              slot="end"
              icon={personCircleOutline}
              color="tertiary"
              size="large"
              style={{ padding: "0 10px" }}
            /> */}
            {/* <IonIcon
                onClick={() => gotoSignIn()}
                className="cursor-pointer"
                slot="end"
                icon={personCircleOutline}
                color="dark"
                size="large"
                style={{ padding: "0 10px" }}
              /> 
             <div className='username'>{platform.username}</div>
              */}
          </IonToolbar>
          <div className="search-bar">
            {/* <IonInput
              className="size-16"
              ref={(ref) => (refSearch.current = ref)}
              onIonChange={(e) => {
                setTerm(e.detail.value!);
              }}
              autofocus={true}
              placeholder="Search"
              clearInput
              color="primary"
              // style={{color:'white !important'}}
            >
              <IonIcon
                className="search-icon size-16 pad-10-lr"
                icon={search}
              />
            </IonInput> */}
            
              <IonIcon
                className="search-icon size-16 pad-10-lr"
                icon={search}
              />
              <input
                type="text"
                className="size-14"
                ref={(ref) => (refSearch.current = ref)}
                onChange={(e) => {
                  setTerm(e.target.value!);
                }}
                autoFocus
                placeholder="Search"
                style={{ background: "transparent", width:'90%' }}
              />
          
          </div>
        </IonHeader>
        <IonContent>
          {/* <div hidden={tracks?.length <= 0} className="page-search-content"> */}
          {tracks && tracks?.length > 0 ? (
            <div className="page-search-content">
              <div className="size-14 pad-10-bottom ">MUSIC VIDEOS</div>
              {/* {console.log('results>>>>', results)} */}

              <div className="search-result">
                {tracks &&
                  tracks.map((res: any, index: number) => {
                    // console.log("res>>>>", res);
                    return (
                      <div
                        key={index}
                        className="result-box"
                        style={{
                          backgroundImage: `url('${res.image.replaceAll(
                            ".jpg",
                            "-en.jpg"
                          )}')`,
                        }}
                        onClick={() => playMedia(index)}
                      >
                        {/* <div className="result-box-title at-center">
                          <div className="pad-5">
                            <div className="media-name"> {res.name}</div>
                            <div className="size-10 color-aaa">
                              {" "}
                              {res?.artists[0]?.name}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div hidden={term === ""} className="size-14 pad-10">
              Record not found.
            </div>
          )}

          {showMediaPlayer && (
            // <MediaPlayer
            //   tracks={tracks}
            //   trackNo={refTrackNo.current}
            //   isOpen={showMediaPlayer}
            //   onClose={() => {
            //     setShowMediaPlayer(false);
            //   }}
            // />

            <ModalAvtPlayer
              playlist={tracks}
              trackNo={refTrackNo.current}
              isOpen={showMediaPlayer}
              onClose={() => {
                setShowMediaPlayer(false);
              }}
            />
          )}
        </IonContent>
      </IonModal>
      <UserAccessModal
        isOpen={modalUserAccess.isOpen}
        onClose={() => {
          setModalUserAccess({ isOpen: false });
        }}
      ></UserAccessModal>
    </>
  );
};

export default SearchModal;
