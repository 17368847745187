import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonList,
  IonListHeader,
  IonItem,
  useIonPopover,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  search,
  personCircleOutline,
  homeSharp,
  homeOutline,
  // albumsSharp,
  // albumsOutline,
  // searchOutline,
  // download,
  // downloadOutline,
  arrowDownCircle,
  fileTrayFullSharp,
  fileTrayFullOutline,
} from "ionicons/icons";
import { useEffect, useState, useContext } from "react";
// import { Route, Redirect } from "react-router";
import { Route, Switch } from "react-router";
import { PlatformContext } from "../contexts/PlatformContext";
import Downloads from "../pages/Downloads";
import Home from "../pages/Home";
import Releases from "../pages/Releases";
import SignIn from "../pages/SignIn";
import DashboardAdmin from "./DashboardAdmin";
import DashboardUser from "./DashboardUser";
import NotFoundPage from "./NotFoundPage";
// import Search from "../pages/Search"
import SearchModal from "./SearchModal";
import UserAccessModal from "./UserAccessModal";
import queryString from "query-string";
import MyList from "./MyList";
import AppUrlListener from "./common/AppUrlListener";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

const titleCase = (str: any) => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
};

const PopoverList: React.FC<{
  onHide: () => void;
  onAdminDashboard: () => void;
  onChannel: () => void;
  onSignIn: () => void;
  onSignOut: () => void;
  username: any;
  pid: any;
}> = ({
  onHide,
  onChannel,
  onAdminDashboard,
  onSignIn,
  onSignOut,
  username,
  pid,
}) => (
  <IonList>
    {username ? (
      <IonItem lines="none" button onClick={onSignOut}>
        Sign Out
      </IonItem>
    ) : (
      <IonItem lines="none" button onClick={onSignIn}>
        Sign In
      </IonItem>
    )}
    {/* <IonItem detail={false} button onClick={onHide}>
      Close
    </IonItem> */}
  </IonList>
);

const PopoverListX: React.FC<{
  onHide: () => void;
  onAdminDashboard: () => void;
  onChannel: () => void;
  onSignIn: () => void;
  onSignOut: () => void;
  username: any;
  pid: any;
}> = ({
  onHide,
  onChannel,
  onAdminDashboard,
  onSignIn,
  onSignOut,
  username,
  pid,
}) => (
  <IonList>
    {console.log("username>>>", username, pid)}
    {username ? (
      <>
        {" "}
        <IonListHeader>
          <div className="at-center user-icon">{username?.substr(0, 1)} </div>
          {username}
        </IonListHeader>
        {pid === "1" && (
          <IonItem button onClick={onAdminDashboard}>
            Admin Dashboard
          </IonItem>
        )}
        <IonItem lines="none" button onClick={onSignOut}>
          Sign Out
        </IonItem>
        {/**********  temporarily disabled on 2021-11-11 
        
        <IonItem lines="none" button onClick={onChannel}>
          Your Channel
        </IonItem> 
        
        */}
      </>
    ) : (
      <IonItem lines="none" button onClick={onSignIn}>
        Sign In
      </IonItem>
    )}

    {/* <IonItem detail={false} button onClick={onHide}>
      Close
    </IonItem> */}
  </IonList>
);

const Menu: React.FC<any> = () => {
  const { platform } = useContext(PlatformContext);

  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onAdminDashboard: () => {
      window.location.href = `/admin-dashboard`;
      dismiss();
    },
    onChannel: () => {
      window.location.href = `/user/${platform.username}`;
      dismiss();
    },
    onSignIn: () => {
      setModalUserAccess({ isOpen: true });
      // if (false && platform.name === "web") {
      if (platform.name === "web") {
        gotoSignIn();
      }
      dismiss();
    },
    onSignOut: () => {
      localStorage.clear();
      window.location.href = "/";
      // if (false && platform.name === "web") {
      if (platform.name === "web") {
        gotoSignOut();
      }
      dismiss();
    },
    username: platform.username,
    pid: platform.pid,
  });
  const [selectedTab, setSelectedTab] = useState("Home");
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [modalUserAccess, setModalUserAccess] = useState({ isOpen: false });
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = (top: number) => {
    // console.log('top>>>', top);
    if (top) setScrollTop(top);
  };
  useEffect(() => {
    setSelectedTab(titleCase(window.location.pathname.replaceAll("/", "")));
    // console.log("window.location.pathname>>>", window.location.pathname);

    let params = queryString.parse(window.location.search);
    if (params.eml && params.uid) {
      localStorage.setItem("eml", params.eml + "");
      localStorage.setItem("uid", params.uid + "");
      window.location.href = "/";
    }
    if (platform.name !== "web") {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
    if (!platform.email && platform.name !== "web") {
      /*********** temporarily disabled on 2022-02-28
       setModalUserAccess({ isOpen: true });
       ***********/
    }
  }, []);

  const gotoSignIn = () => {
    if (platform.name === "web") {
      const url = "https://artist.takeone.studio/signin";
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };
  const gotoSignOut = () => {
    if (platform.name === "web") {
      localStorage.clear();
      // const url = "https://artist.takeone.studio/signout";
      // window.location.href = url;
    }
  };
  return (
    <>
      <IonReactRouter>
        <AppUrlListener />
        <IonHeader className={`top-header ion-no-border ${platform.name}`}>
          {(platform.name === "web" ||
            (platform.name !== "web" && scrollTop < 100)) && (
            <IonToolbar
              className={`top-menu ${scrollTop > 100 ? "transparent" : ""}`}
            >
              <IonTitle style={{ maxWidth: "80px" }}>
                <a href="/home" className="logo-container">
                  <img
                    className="main-logo"
                    src="./assets/img/takeone-logo.png"
                    alt=""
                    width={40}
                  />
                </a>
              </IonTitle>
              {/* <IonTitle>
              {selectedTab === "Home" || selectedTab === "" ? (
                <a href="/home"  className="logo-container">
                  <img className="main-logo"
                    // src="./assets/img/takeone-logo-word.png"
                    // height="24"
                    src="./assets/img/takeone-logo.png"
 
                    alt=""
                  />
                </a>
              ) : (
                <a href="/home" className="logo-container">
                  <img className="main-logo"
                    // src="./assets/img/takeone-logo-word.png"
                    // height="24"
                    src="./assets/img/takeone-logo.png"
                  
                    alt=""
                  />
                </a>
                // selectedTab.replace("User", "")
              )}
            </IonTitle> */}
              <IonIcon
                className="cursor-pointer"
                slot="end"
                icon={search}
                onClick={() => setShowSearchModal(true)}
                size="tiny"
                style={{ zoom: "1.5", padding: "0 5px" }}
              />
              {platform?.username ? (
                <IonLabel
                  className="cursor-pointer at-center user-icon"
                  slot="end"
                  onClick={(e) =>
                    present({
                      event: e.nativeEvent,
                    })
                  }
                >
                  {platform.username?.substr(0, 1)}{" "}
                </IonLabel>
              ) : (
                <IonIcon
                  // onClick={() => gotoSignIn()}
                  //---- disabled on 2021-11-17
                  onClick={(e) =>
                    present({
                      event: e.nativeEvent,
                    })
                  }
                  className="cursor-pointer"
                  slot="end"
                  icon={personCircleOutline}
                  color="dark"
                  size="large"
                  style={{ padding: "0 10px" }}
                />
              )}
              {/* <div>
              
              <div className="at-center user-icon">
                {platform.username.substr(0, 1)}{" "}
              </div>
              {platform.username}
            </div> */}

              {/* <div className="username">{platform.username}</div> */}
              {/* <a href="/home" className="logo-container">
            <img
              className="main-logo"
              src="./assets/img/takeone-logo.png"
              alt=""
            />
          </a> */}
            </IonToolbar>
          )}
        </IonHeader>
        <IonTabs>
          <IonRouterOutlet>
            <Switch>
              {/* <Route path="/home" component={Home} exact={true} /> */}
              <Route
                path="/home"
                exact={true}
                render={() => <Home onScroll={onScroll} />}
              />
              <Route path="/mylist" component={MyList} exact={true} />
              <Route path="/releases" component={Releases} exact={true} />

              {/* <Route path="/signin" component={SignIn} exact={true} /> */}

              <Route path="/signin/:uveid?">
                <SignIn />
              </Route>

              {/* <Route path="/search" component={Search} exact={true} /> */}
              <Route path="/downloads" component={Downloads} exact={true} />
              {platform.pid === "1" && (
                <Route
                  path="/admin-dashboard"
                  component={DashboardAdmin}
                  exact={true}
                />
              )}
              <Route
                path="/user/:username"
                component={DashboardUser}
                exact={true}
              />
              <Route
                exact
                path="/"
                // render={() => <Redirect to="/home" />}
                render={() => <Home onScroll={onScroll} />}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </IonRouterOutlet>

          <IonTabBar
            hidden={["web"].includes(platform.name)} //-- temporarily disabled
            ///////  hidden={true}
            slot="bottom"
            selectedTab="home"
            onIonTabsDidChange={(e) => setSelectedTab(e.detail.tab)}
          >
            <IonTabButton tab="Home" href="/home">
              <IonIcon
                icon={
                  selectedTab.toLowerCase() === "home" ? homeSharp : homeOutline
                }
              />
              {/* <IonIcon icon={home} /> */}
              <IonLabel>Home</IonLabel>
            </IonTabButton>

            <IonTabButton tab="MyList" href="/mylist">
              <IonIcon
                icon={
                  selectedTab === "mylist"
                    ? fileTrayFullSharp
                    : fileTrayFullOutline
                }
              />
              <IonLabel>My List</IonLabel>
            </IonTabButton>

            {/* <IonTabButton tab="Releases" href="/releases">
 
              <IonIcon
                icon={
                  selectedTab.toLowerCase() === "releases"
                    ? albumsSharp
                    : albumsOutline
                }
              />
              <IonLabel>Releases</IonLabel>
            </IonTabButton>
             */}
            {/* <IonTabButton tab="Search" href="/search">
              <IonIcon icon={selectedTab.toLowerCase() === 'search' ? search : searchOutline} />
              <IonLabel>Search</IonLabel>
            </IonTabButton> */}

            {!["ios", "web"].includes(platform.name) && (
              <IonTabButton tab="Downloads" href="/downloads">
                <IonIcon
                  icon={
                    selectedTab.toLowerCase() === "downloads"
                      ? arrowDownCircle
                      : arrowDownCircle
                  }
                />
                <IonLabel>Downloads</IonLabel>
              </IonTabButton>
            )}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
      <UserAccessModal
        isOpen={modalUserAccess.isOpen}
        onClose={() => {
          setModalUserAccess({ isOpen: false });
        }}
      ></UserAccessModal>

      <SearchModal
        isOpen={showSearchModal}
        onClose={() => setShowSearchModal(false)}
      />

      {/* {console.log('Menu platform >>>>>>>', platform)} */}
    </>
  );
};

export default Menu;
